import React, { useEffect, useState } from 'react';
import Toggle from 'components/Toggle';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import { URL_HELPERS, URL_TOGGLE } from 'helpers/url';

const useStyles = makeStyles(() => ({
  toggle: {
    width: 'calc(100% - 50px)',
    display: 'flex',
    position: 'absolute',
  },
}));

const RouteToggle = () => {
  const PORTAL = 'Portal';
  const STAKING = 'Staking';
  const history = useHistory();
  const [value, setValue] = useState(PORTAL);
  const classes = useStyles();

  useEffect(() => {
    if (history?.location?.pathname) {
      const pathname = history.location.pathname;
      const isPortal = URL_TOGGLE.portal.includes(pathname);
      const isStaking = URL_TOGGLE.staking.includes(pathname);
      if (isPortal) {
        setValue(PORTAL);
      }
      if (isStaking) {
        setValue(STAKING);
      }
    }
  }, [history?.location?.pathname]);

  const onChange = value => {
    setValue(value);
    if (value === PORTAL) {
      history.push(URL_HELPERS.home);
    } else {
      history.push(URL_HELPERS.stake);
    }
  };

  return (
    <div className={classes.toggle}>
      <Toggle
        onChange={onChange}
        value={value}
        option1={PORTAL}
        option2={STAKING}
      />
    </div>
  );
};

export default RouteToggle;
