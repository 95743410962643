import React, { useContext, useEffect, useState } from 'react';
import {
  Typography,
  Button,
  Input,
  IconButton,
  InputAdornment,
  Grid,
} from '@material-ui/core';
import { STORE } from 'store';
import ForwardIcon from '@material-ui/icons/ForwardRounded';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownwardRounded';
import WalletContext from 'contexts/WalletContext';
import { find, get, isEmpty } from 'lodash';
import { useWallets } from 'hooks/wallets';
import { useBalances } from 'hooks/balances';
import { STRING_HELPERS } from 'helpers/stringAdditions';
import { useSetStoreValue } from 'react-context-hook';
import { ETHERS_SERVICE } from 'services/ethers';
import { LOGIC_HELPERS } from 'helpers/logic';
import { WALLETS_API } from 'apis/wallets';
import Loading from 'components/Loading';
import { URL_HELPERS } from 'helpers/url';
import useStyles from './styles';

const BuyChips = props => {
  const { history } = props;
  const classes = useStyles();
  const [hash, setHash] = useState('');
  const [amount, setAmount] = useState(0);
  const [balance, setBalance] = useState('0.0');
  const [refetch, setRefetch] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const setSnackbar = useSetStoreValue(STORE.SNACKBAR);

  const { provider, wallet, validNetwork, loadWeb3Modal } =
    useContext(WalletContext);
  const address = get(wallet, 'wallet', '').toLowerCase();
  const networkId = get(wallet, 'networkId', 0);

  const { data: wallets } = useWallets();
  const { data: balances, refetch: refetchBalances } = useBalances();
  const chipBalance = get(balances, '1.balance', '0.0');

  const maxValue = balance;
  const displayAddress = STRING_HELPERS.renderWalletAddress(address);

  useEffect(() => {
    const fetchBalance = async () => {
      const [, , ethersHelper] = ETHERS_SERVICE(provider, address, networkId);
      const res = await ethersHelper.methods.checkPKRBalance();
      setBalance(res);
    };

    const initialized = LOGIC_HELPERS.ifElse(
      [provider, validNetwork, wallets?.length],
      true,
      false,
    );
    if (initialized) {
      const connected = find(wallets, { address });
      const status = !isEmpty(connected);
      setEnabled(status);
      setRefetch(false);

      if (status) {
        fetchBalance();
      }
    }
  }, [provider, validNetwork, address, hash, wallets, refetch]);

  const handleChange = e => {
    const pattern =
      /^((\d{1,3})([0-9]{3}){0,1}|(\d{1})([0-9]{3}){0,2}|(\d{1,7}))(\.\d{1,18})?$/i;
    const value = e.target.value;
    const match = value.match(pattern);

    if (match) {
      if (parseFloat(match) > 0 && parseFloat(match) <= parseFloat(maxValue)) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    } else {
      setDisabled(true);
    }

    setAmount(value);
  };

  const onMaxInput = () => {
    setAmount(balance);
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      setHash(null);
      const [, , ethersHelper] = ETHERS_SERVICE(provider, address, networkId);
      const tx = await ethersHelper.methods.depositPKR(amount);
      const payload = { txHash: tx.hash, asset_id: 1 };
      await WALLETS_API.deposit(payload);
      setHash(tx.hash);

      setSnackbar({
        variant: 'success',
        message: 'Success buying CHIPS!',
      });
      setAmount(0);
      setLoading(false);
    } catch (e) {
      setSnackbar({
        variant: 'error',
        message: 'Failed buying CHIPS!',
      });
      setAmount(0);
      setLoading(false);
      console.log('onSubmit ERROR: ', e);
    }
  };

  const onSync = async () => {
    try {
      setLoading(true);
      setRefetch(true);
      await WALLETS_API.sync();
      await refetchBalances();
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const onRedirectHome = () => {
    history.push(URL_HELPERS.home);
  };

  const renderInvalidNetwork = () => (
    <div className={classes.note}>
      <Typography variant="h6" color="textPrimary" className={classes.header}>
        NETWORK INVALID!
      </Typography>
      <Typography variant="subtitle1" color="textPrimary">
        Please change to Ethereum network and connect your wallet again.
      </Typography>
    </div>
  );

  const renderConnectProvider = () => (
    <div className={classes.note}>
      <Typography variant="h6" color="textPrimary" className={classes.header}>
        WALLET PROVIDER NOT CONNECTED
      </Typography>
      <Typography variant="subtitle1" color="textPrimary">
        Connect to a wallet provider first.
      </Typography>
      <Button
        color="secondary"
        variant="contained"
        onClick={loadWeb3Modal}
        className={classes.lightButton}
        endIcon={<ForwardIcon />}
      >
        Connect wallet provider
      </Button>
    </div>
  );

  const renderDisabled = () => (
    <div className={classes.note}>
      <Typography variant="h6" color="textPrimary" className={classes.header}>
        CURRENT WALLET NOT CONNECTED!
      </Typography>
      <Typography variant="subtitle1" color="textPrimary">
        Please go to Home page to connect your wallet.
      </Typography>
      <Button
        color="secondary"
        variant="contained"
        onClick={onRedirectHome}
        className={classes.lightButton}
        endIcon={<ForwardIcon />}
      >
        Go to Home
      </Button>
    </div>
  );

  const renderSync = () => (
    <div className={classes.note}>
      <Typography className={classes.noteText}>
        <b>Note:</b> Please wait a few minutes for your transaction to finish
        and click “SYNC” at the end, in order to update your balance.
      </Typography>
      <Button
        onClick={onSync}
        variant="contained"
        className={classes.lightButton}
      >
        SYNC
      </Button>
    </div>
  );

  const renderHash = () => {
    if (!hash) {
      return null;
    }

    return (
      <Typography className={classes.hash} color="textPrimary">
        You can check if the transaction is done{' '}
        <a
          href={`https://ropsten.etherscan.io/tx/${hash}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          here
        </a>
        .
      </Typography>
    );
  };

  const renderContent = () => {
    if (!validNetwork) {
      return renderInvalidNetwork();
    }

    if (!provider) {
      return renderConnectProvider();
    }

    if (!enabled) {
      return renderDisabled();
    }

    return (
      <Grid item className={classes.walletRoot}>
        <Typography
          className={classes.connectedWallet}
          variant="body2"
          color="textPrimary"
          gutterBottom
        >
          <b>Connected wallet:</b> {displayAddress}
        </Typography>
        <div className={classes.content}>
          <Typography className={classes.inputLabel}>From</Typography>
          <div className={classes.inputRoot}>
            <Input
              type="number"
              value={amount}
              aria-valuemax={balance}
              onChange={handleChange}
              disableUnderline
              className={classes.input}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={onMaxInput}
                  >
                    <Typography variant="subtitle2" className={classes.max}>
                      MAX
                    </Typography>
                  </IconButton>
                </InputAdornment>
              }
            />
            <Typography className={classes.splitter}>|</Typography>
            <Typography variant="body2" className={classes.pkr}>
              PKR
            </Typography>
          </div>
          <Typography color="textPrimary" className={classes.balance}>
            Balance: <b className={classes.balanceValue}>{balance} PKR</b>
          </Typography>
          <Typography className={classes.inputLabel}>To</Typography>
          <div className={classes.inputRoot}>
            <Input
              type="text"
              value={amount}
              onChange={handleChange}
              className={classes.input}
              disableUnderline
            />
            <Typography variant="body2" className={classes.chips}>
              CHIPS
            </Typography>
          </div>
          <Typography color="textPrimary" className={classes.balance}>
            Balance:{' '}
            <b className={classes.chipBalanceValue}>{chipBalance} CHIP</b>
          </Typography>
          {loading ? (
            <div>
              <Loading loadingText="Please wait..." />
            </div>
          ) : (
            <Button
              color="primary"
              variant="contained"
              onClick={onSubmit}
              disabled={disabled}
              className={classes.button}
            >
              Buy chips
            </Button>
          )}
          {renderHash()}
          {renderSync()}
        </div>
      </Grid>
    );
  };

  const renderBalance = () => {
    const deals = [
      {
        chips: 200,
        value: 19.99,
      },
      {
        chips: 400,
        value: 24.99,
      },
      {
        chips: 600,
        value: 15.99,
      },
    ];

    return (
      <Grid item className={classes.balanceRoot}>
        <div className={classes.balanceContent}>
          <Typography color="textPrimary" className={classes.balanceHeader}>
            BALANCE
          </Typography>
          <div className={classes.circle}>
            <div className={classes.circleContent}>
              <Typography variant="h2">{chipBalance}</Typography>
              <Typography>CHIPS</Typography>
            </div>
          </div>
          <div className={classes.line} />
          <Typography className={classes.dealHeader}>DEALS</Typography>
          <Grid
            md
            alignItems="center"
            container
            className={classes.dealsContainer}
          >
            {deals.map((item, index) => {
              return (
                <Grid item key={index} className={classes.dealsCard}>
                  <Typography className={classes.chipValue}>
                    {item.chips}
                  </Typography>
                  <Typography className={classes.chipText}>CHIPS</Typography>
                  <Typography className={classes.splitter}>for</Typography>
                  <Typography color="textPrimary">$ {item.value}</Typography>
                </Grid>
              );
            })}
          </Grid>
        </div>
      </Grid>
    );
  };

  return (
    <>
      <Grid
        container
        spacing={4}
        direction="row"
        md
        alignItems="center"
        className={classes.root}
      >
        {renderContent()}
        {renderBalance()}
      </Grid>
    </>
  );
};

export default BuyChips;
