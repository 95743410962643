import { useQuery, useQueryClient, useMutation } from 'react-query';
import { WALLETS_API } from 'apis/wallets';

export const WALLETS_CACHE_KEY = 'wallets';

const onError = e => {
  throw e;
};

export const useWallets = isPublic => {
  if (isPublic) {
    const request = new Promise(resolve => {
      resolve(null);
    });
    return useQuery(WALLETS_CACHE_KEY, request);
  }

  return useQuery(WALLETS_CACHE_KEY, WALLETS_API.browse);
};

export const useConnectWalletMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(WALLETS_API.connect, {
    onSuccess: () => queryClient.invalidateQueries(WALLETS_CACHE_KEY),
    onError,
  });
};
