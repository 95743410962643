import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
    padding: theme.spacing(4),
  },
  grid: {
    height: '100%',
  },
  headerRoot: {
    paddingBottom: 32,
  },
  connectedHeader: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  header: {
    fontWeight: 'bold',
  },
  network: {
    padding: '16px 16px 4px',
    fontWeight: 'bold',
  },
  address: {
    fontWeight: 'bold',
  },
  addressHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 88,
  },
  image: {
    display: 'inline-block',
    maxWidth: 900,
    width: '100%',
  },
  item: {
    alignItems: 'center',
  },
  card: {
    borderRadius: 4,
    minHeight: 120,
    boxShadow: '1px 1px 0 rgba(255,255,255,.1) inset',
    backgroundColor: '#be39a3',
    background: '#0f0f0f',
  },
}));

export default useStyles;
