import { Button, Dialog, TextField, Typography } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { TRANSACTIONS_LOGIN_API } from 'apis/Transactions/login';
import { useSetStoreValue } from 'react-context-hook';
import ForgotPass from '../../modals/ForgotPassModal';
import React, { useEffect, useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { URL_HELPERS } from 'helpers/url';
import Loading from 'components/Loading';
import OTP from '../../modals/OTPModal';
import validate from 'validate.js';
import useStyles from './styles';
import { STORE } from 'store';

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
  },
};

const LoginForm = props => {
  const [uuid, setUUID] = useState('');
  const [openOTP, setOpenOTP] = useState(false);
  const { history, setResetPassword } = props;
  const [loading, setLoading] = useState(false);

  const handleOpenPass = () => {
    setResetPassword(true);
  };

  const handleClosePass = () => {
    setResetPassword(false);
  };

  const handleOpenOTP = res => {
    const { login_transaction_otp_uuid } = res;
    setUUID(login_transaction_otp_uuid);
    setOpenOTP(true);
  };

  const handleCloseOTP = () => {
    setOpenOTP(false);
  };

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });
  const setSnackBar = useSetStoreValue(STORE.SNACKBAR);
  const classes = useStyles();

  const handleChange = event => {
    event.persist();

    setFormState(prevFormState => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const onSuccess = () => {
    handleCloseOTP();
    setLoading(false);
    history.push(URL_HELPERS.home);
  };

  const onError = () => {
    setLoading(false);
    setSnackBar({
      message: 'Invalid login credentials, try again.',
      variant: 'error',
    });
  };

  const handleSubmit = async event => {
    event.preventDefault();
    const payload = {
      email: formState.values.email,
      password: formState.values.password,
      application: 'kyc-portal',
    };
    setLoading(true);
    try {
      const res = await TRANSACTIONS_LOGIN_API.request(payload);
      setLoading(false);
      handleOpenOTP(res);
    } catch (e) {
      onError(e);
    }
  };

  const hasError = field =>
    !!(formState.touched[field] && formState.errors[field]);

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(prevFormState => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  const renderLoginForm = () => {
    return (
      <div className={classes.root}>
        <Typography className={classes.label}>Username or Email</Typography>
        <TextField
          name="email"
          placeholder="Email"
          className={classes.input}
          error={hasError('email')}
          helperText={hasError('email') ? formState.errors.email[0] : null}
          onChange={handleChange}
          value={formState.values.email || ''}
          fullWidth
          variant="outlined"
        />
        <Typography className={classes.label}>Password</Typography>
        <TextField
          name="password"
          placeholder="Password"
          className={classes.input}
          error={hasError('password')}
          helperText={
            hasError('password') ? formState.errors.password[0] : null
          }
          onChange={handleChange}
          type="password"
          value={formState.values.password || ''}
          fullWidth
          variant="outlined"
        />

        <div className={classes.row}>
          <FormControlLabel
            control={
              <Checkbox
                // checked={state.checkedB}
                // onChange={handleChange}
                name="checkbox"
              />
            }
            label="Remember Me"
            className={classes.label}
          />
          <Button
            className={classes.changePassButton}
            color="primary"
            onClick={handleOpenPass}
          >
            Forgot Password?
          </Button>
        </div>

        {loading ? (
          <Loading loadingText="Please wait..." />
        ) : (
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={!formState.isValid}
            className={classes.logInButton}
            fullWidth
            onClick={handleSubmit}
          >
            Log In
          </Button>
        )}
      </div>
    );
  };

  return (
    <>
      {openOTP ? (
        <OTP
          uuid={uuid}
          formState={formState}
          onSuccess={onSuccess}
          onClose={handleCloseOTP}
        />
      ) : (
        renderLoginForm()
      )}
    </>
  );
};

export default LoginForm;
