import React from 'react';
import { useStoreState } from 'react-context-hook';
import { Redirect } from 'react-router-dom';
import { URL_HELPERS } from 'helpers/url';
import ReactJson from 'react-json-view';

function StoreViewer() {
  const store = useStoreState();
  // eslint-disable-next-line no-undef
  const secure = process.env.NODE_ENV === 'production';

  if (secure) return <Redirect to={URL_HELPERS.notFound} />;

  const src = {
    ...store,
  };

  return (
    <ReactJson
      style={{ minHeight: '100%' }}
      name="Game Portal Store"
      theme="railscasts"
      collapsed={1}
      src={src}
    />
  );
}

export default StoreViewer;
