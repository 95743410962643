import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import useAuth from 'hooks/useAuth';
import { getRefreshToken } from 'store';
import { forceLogout } from 'store';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { AppBar, Toolbar, Hidden, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import InputIcon from '@material-ui/icons/Input';
import RouteToggle from 'components/RouteToggle';
import { URL_HELPERS } from 'helpers/url';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    backgroundColor: theme.palette.dark,
    borderBottom: `1px solid ${theme.palette.border}`,
  },
  flexGrow: {
    flexGrow: 1,
  },
  buttons: {
    color: theme.palette.primary.main,
  },
  logoMobileLink: {
    flex: 1,
    textAlign: 'center',
  },
  logoMobile: {
    flex: 1,
    width: 80,
  },
  logo: {
    width: 90,
  },
}));

const Topbar = props => {
  const { className, onSidebarOpen, history } = props;
  const auth = useAuth();

  const classes = useStyles();

  const fallbackLogout = () => {
    forceLogout();
    setTimeout(() => {
      history.replace(URL_HELPERS.login);
    }, 500);
  };

  const onLogout = async () => {
    const payload = { refresh_token: getRefreshToken() };
    try {
      await auth.signOut(payload);
      fallbackLogout();
    } catch (e) {
      fallbackLogout();
      console.log('ERROR: ', e);
    }
  };

  return (
    <AppBar className={clsx(classes.root, className)}>
      <Toolbar>
        <Hidden smDown>
          <RouterLink to={URL_HELPERS.stake}>
            {' '}
            {/* update to home */}
            <img alt="Logo" className={classes.logo} src="/images/logo.png" />
          </RouterLink>
          <div className={classes.flexGrow} />
          {/* <RouteToggle /> */} {/* Update back */}
        </Hidden>
        <Hidden mdUp>
          <IconButton className={classes.buttons} onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
          <RouterLink to={URL_HELPERS.stake} className={classes.logoMobileLink}>
            {' '}
            {/* update to home */}
            <img
              alt="Logo"
              className={classes.logoMobile}
              src="/images/logo.png"
            />
          </RouterLink>
        </Hidden>
        <IconButton onClick={onLogout} className={classes.buttons}>
          <InputIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  history: PropTypes.object,
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
};

export default withRouter(Topbar);
