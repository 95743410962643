import React, { useState, useContext } from 'react';
import { STORE } from 'store';
import { find, get, isEmpty } from 'lodash';
import { useConnectWalletMutation, useWallets } from 'hooks/wallets';
import { LOGIC_HELPERS } from 'helpers/logic';
import { ETHERS_SERVICE } from 'services/ethers';
import WalletContext from 'contexts/WalletContext';
import { useSetStoreValue } from 'react-context-hook';
import { STRING_HELPERS } from 'helpers/stringAdditions';
import { TRANSACTIONS_CONNECT_API } from 'apis/Transactions/connect';
import { Button, Tooltip, Typography, withStyles } from '@material-ui/core';
import Loading from 'components/Loading';
import useStyles from './styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const ConnectWallet = props => {
  const { staking } = props;
  const [loading, setLoading] = useState(false);
  const setSnackbar = useSetStoreValue(STORE.SNACKBAR);
  const classes = useStyles();

  const { provider, wallet, loadWeb3Modal, logoutOfWeb3Modal, validNetwork } =
    useContext(WalletContext);
  const address = get(wallet, 'wallet', '').toLowerCase();
  const networkId = get(wallet, 'networkId', 0);

  const { mutateAsync: onConnectWallet } = useConnectWalletMutation();
  const { data: wallets, isLoading, isError } = useWallets();

  const disabled = LOGIC_HELPERS.ifElse(
    [isLoading, isError],
    true,
    false,
    true,
  );

  const handleWallet = () => {
    if (!validNetwork) {
      setSnackbar({
        variant: 'error',
        message: 'Network is invalid! Please change to ethereum network.',
      });
    }
    if (!provider) {
      loadWeb3Modal();
    }
  };

  const onConnect = async () => {
    try {
      setLoading(true);
      const res = await TRANSACTIONS_CONNECT_API.request();
      const [ethers, signer] = ETHERS_SERVICE(provider, address, networkId);
      const rawSignature = await signer.signMessage(res.message);
      const signature = await ethers.utils.splitSignature(rawSignature);
      const payload = {
        address,
        nonce: res.nonce,
        v: signature.v,
        r: signature.r,
        s: signature.s,
      };

      await onConnectWallet(payload);
      setSnackbar({
        variant: 'success',
        message: 'Wallet connected successfully!',
      });
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const onDisconnect = async () => {
    await logoutOfWeb3Modal();
  };

  const renderConnectButton = connected => {
    const submitFunc = LOGIC_HELPERS.ifElse(connected, onDisconnect, onConnect);
    const displayText = LOGIC_HELPERS.ifElse(
      connected,
      'DISCONNECT WALLET',
      'CONNECT WALLET',
    );

    return (
      <Button
        onClick={submitFunc}
        className={classes.button}
        disabled={disabled}
        variant="contained"
        color="primary"
        size="large"
        fullWidth
      >
        {displayText}
      </Button>
    );
  };

  const text = validNetwork ? 'Connect Wallet' : 'Change Network';

  if (provider && validNetwork && !isEmpty(wallet)) {
    const displayAddress = STRING_HELPERS.renderWalletAddress(address);
    const connected = find(wallets, { address });
    const status = !isEmpty(connected);
    const displayStatus = LOGIC_HELPERS.ifElse(
      status,
      '(CONNECTED)',
      '(NOT CONNECTED)',
    );

    if (staking) {
      return (
        <Button
          onClick={onDisconnect}
          variant="contained"
          color="primary"
          size="large"
        >
          {displayAddress}
        </Button>
      );
    }

    return (
      <>
        {/* <Loading loading={loading} loadingText="Connecting wallet" /> */}
        <div className={classes.connected}>
          <Typography variant="button" className={classes.title}>
            CURRENT WALLET
          </Typography>
          <LightTooltip
            title={address}
            placement="top-start"
            arrow={false}
            interactive
          >
            <Typography variant="subtitle2" className={classes.address}>
              {displayAddress} <b>{displayStatus}</b>
            </Typography>
          </LightTooltip>
          {renderConnectButton(status)}
        </div>
      </>
    );
  }

  if (staking) {
    const buttonText = validNetwork ? 'Connect Wallet' : 'Change Network';

    return (
      <Button
        onClick={handleWallet}
        variant="contained"
        color="primary"
        size="large"
      >
        {buttonText}
      </Button>
    );
  }

  return (
    <Button
      onClick={handleWallet}
      className={classes.connect}
      color="secondary"
      size="large"
      fullWidth
    >
      <div className={classes.connectContent}>
        <AddCircleOutlineIcon className={classes.connectIcon} />
        {text}
      </div>
    </Button>
  );
};

export default ConnectWallet;
