import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    padding: theme.spacing(4),
    justifyContent: 'center',
    textAlign: 'center',
  },
  headerRoot: {
    paddingBottom: 32,
  },
  header: {
    fontWeight: 'bold',
  },
  content: {
    padding: 40,
    maxWidth: 400,
    minHeight: 620,
    display: 'flex',
    borderRadius: 20,
    flexDirection: 'column',
    backgroundColor: theme.palette.dark,
    margin: 'auto',
  },
  note: {
    marginTop: 20,
  },
  noteConnect: {
    marginTop: 20,
    maxWidth: '40%',
    margin: 'auto',
  },
  noteText: {
    textAlign: 'justify',
    fontSize: 12,
    color: theme.palette.secondary.main,
  },
  lightButton: {
    minWidth: '100%',
    border: 'solid 2px #fff',
    borderRadius: 10,
    marginTop: 16,
    padding: '8px 0',
    fontSize: 16,
    fontWeight: 'bolder',
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.dark,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover svg': {
      position: 'relative',
      animationName: '$slide',
      animationDuration: '0.8s',
      animationTimingFunction: 'linear',
      animationIterationCount: '8',
    },
  },
  '@keyframes slide': {
    '0%, 100%': { left: 0 },
    '50%': { left: 4 },
  },
  max: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  balance: {
    fontSize: 14,
    marginTop: 8,
    alignSelf: 'flex-end',
  },
  pkr: {
    padding: '10px 12px',
    fontWeight: 'bold',
    backgroundColor: theme.palette.background.default,
    color: 'rgba(194, 195, 197, 0.65)',
    textAlign: 'center',
    lineHeight: 2,
    borderRadius: 10,
  },
  chips: {
    padding: '10px 16px',
    fontWeight: 'bold',
    backgroundColor: theme.palette.background.default,
    color: 'rgba(194, 195, 197, 0.65)',
    textAlign: 'center',
    lineHeight: 2,
    borderRadius: 10,
  },
  splitter: {
    color: theme.palette.secondary.dark,
  },
  inputRoot: {
    display: 'flex',
    minWidth: 60,
    borderRadius: 10,
    alignItems: 'center',
    paddingLeft: 16,
    backgroundColor: theme.palette.background.default,
  },
  input: {
    flex: 1,
    margin: '8px 0',
  },
  arrowIcon: {
    width: 30,
    height: 30,
    padding: 2,
    margin: '16px 0',
    textAlign: 'center',
    borderRadius: '50%',
    alignSelf: 'center',
    border: `1px solid ${theme.palette.primary.main}`,
  },
  button: {
    marginTop: 16,
    fontSize: 16,
    padding: '8px 0',
    fontWeight: 'bold',
    backgroundColor: theme.palette.primary.main,
  },
  hash: {
    marginTop: 16,
    fontSize: 12,
  },
  connectedWallet: {
    color: theme.palette.secondary.dark,
    marginTop: 32,
  },
  inputLabel: {
    textAlign: 'left',
    color: theme.palette.secondary.dark,
  },
  dealHeader: {
    marginTop: 14,
    marginRight: -10.8,
    letterSpacing: 10.8,
    fontSize: 18,
    color: theme.palette.secondary.dark,
  },
  dealsContainer: {
    marginTop: 24,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  dealsCard: {
    backgroundColor: theme.palette.dark,
    height: 216,
    width: 200,
    borderRadius: 15,
    padding: '20px 30px',
  },
  chipValue: {
    color: theme.palette.primary.main,
    fontSize: 50,
    fontWeight: 'bold',
  },
  chipText: {
    color: theme.palette.primary.main,
  },
}));

export default useStyles;
