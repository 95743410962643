import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import useStyles from './styles';

const Settings = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container justify="center" spacing={4} className={classes.grid}>
        <Grid item>
          <div className={classes.content}>
            <Typography variant="h1" color="textPrimary">
              SETTINGS
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Settings;
