import { get } from 'lodash';
import React, { useContext } from 'react';
import { useWallets } from 'hooks/wallets';
import { Grid, Typography } from '@material-ui/core';
import ConnectWalletButton from 'components/ConnectWallet';
import EthereumWallet from './components/EthereumWallet';
import WalletContext from 'contexts/WalletContext';
import useStyles from './styles';

const Home = () => {
  const classes = useStyles();
  const { data } = useWallets();
  const { provider, wallet, loadWeb3Modal, logoutOfWeb3Modal, validNetwork } =
    useContext(WalletContext);
  const address = get(wallet, 'wallet', '').toLowerCase();
  const networkId = get(wallet, 'networkId', 0);

  const renderWallets = () => {
    if (!data) {
      return null;
    }
    return data.map((item, key) => (
      <EthereumWallet
        key={key}
        wallet={item}
        activeAddress={address}
        provider={provider}
        networkId={networkId}
      />
    ));
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2} justifyContent="center">
        {renderWallets()}
        <Grid className={classes.item}>
          <ConnectWalletButton />
        </Grid>
      </Grid>
    </div>
  );
};

export default Home;
