import React, { useState, useEffect } from 'react';
import { find, get, isEmpty } from 'lodash';
import { LOGIC_HELPERS } from 'helpers/logic';
import {
  Card,
  Grid,
  CardContent,
  Typography,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import { useConnectWalletMutation, useWallets } from 'hooks/wallets';
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import WalletContext from 'contexts/WalletContext';
import useStyles from './styles';
import { ETHERS_SERVICE } from 'services/ethers';

import { STRING_HELPERS } from 'helpers/stringAdditions';

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const EthereumWallet = props => {
  const { wallet, activeAddress, provider, networkId } = props;
  const classes = useStyles();
  const address = get(wallet, 'address', '').toLowerCase();
  const connected = wallet.address === activeAddress;
  const [balance, setBalance] = useState(0);
  const displayTextStatus = LOGIC_HELPERS.ifElse(connected, 'Active', '');

  useEffect(() => {
    const fetchBalance = async () => {
      const [, , ethersHelper] = ETHERS_SERVICE(
        provider,
        activeAddress,
        networkId,
      );
      const res = await ethersHelper.methods.checkPKRBalance(address);
      setBalance(res);
    };
    if (provider) {
      fetchBalance();
    }
  }, [provider]);

  const renderTooltip = () => (
    <Typography variant="body2">{address}</Typography>
  );

  const renderContent = () => {
    const displayAddress = STRING_HELPERS.renderWalletAddress(address);

    return (
      <div className={classes.content}>
        <div className={classes.addressHeader}>
          <Typography
            variant="button"
            color="textPrimary"
            className={classes.address}
            gutterBottom
          >
            ADDRESS
          </Typography>
          {/* <Typography
            variant="button"
            color="textPrimary"
            className={classes.network}
            gutterBottom
          >
            ETHEREUM
          </Typography> */}
        </div>
        <LightTooltip
          title={renderTooltip(address)}
          placement="bottom-start"
          arrow={false}
          interactive
        >
          <Typography
            variant="h4"
            color="textPrimary"
            className={
              connected ? classes.connectedAddress : classes.disconnectedAddress
            }
            gutterBottom
          >
            {displayAddress}
          </Typography>
        </LightTooltip>
        <div className={classes.addressFooter}>
          <div className={classes.statusFooter}>
            {connected ? (
              <FiberManualRecordIcon className={classes.connectedStatus} />
            ) : null}
            <Typography
              className={
                connected ? classes.connectedStatus : classes.disconnectedStatus
              }
            >
              {displayTextStatus}
            </Typography>
          </div>
          <Typography
            className={
              connected ? classes.connectedBalance : classes.disconnectedBalance
            }
          >
            {balance} PKR
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <Grid className={classes.item}>
      <Card
        className={connected ? classes.connectedCard : classes.disconnectedCard}
      >
        <CardContent>{renderContent()}</CardContent>
      </Card>
    </Grid>
  );
};

export default EthereumWallet;
