import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import { Topbar } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 56,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64,
    },
  },
  content: {
    height: '100%',
  },
  primary: {
    backgroundColor: theme.palette.background.default,
  },
}));

const Minimal = props => {
  const { children, primary } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Topbar />
      <main
        className={clsx(classes.content, {
          [classes.primary]: primary,
        })}
      >
        {children}
      </main>
    </div>
  );
};

Minimal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Minimal;
