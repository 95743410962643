import { Button, Dialog, TextField, Typography } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { TRANSACTIONS_LOGIN_API } from 'apis/Transactions/login';
import { useSetStoreValue } from 'react-context-hook';
import ForgotPass from '../../modals/ForgotPassModal';
import React, { useEffect, useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { URL_HELPERS } from 'helpers/url';
import OTP from '../../modals/OTPModal';
import validate from 'validate.js';
import useStyles from './styles';
import { STORE } from 'store';
import { USERS_API } from 'apis/user';

const schema = {
  new_password: {
    presence: { allowEmpty: false, message: 'is required' },
  },
  confirm_password: {
    presence: { allowEmpty: false, message: 'is required' },
  },
};

const NewPasswordForm = props => {
  const {
    uuid,
    handleOpenCompleteReset,
    handleCloseChangePass,
    setResetPassword,
  } = props;

  const handleClosePass = () => {
    setResetPassword(false);
  };

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });
  const setSnackBar = useSetStoreValue(STORE.SNACKBAR);
  const classes = useStyles();

  const handleChange = event => {
    event.persist();

    setFormState(prevFormState => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const onSuccess = res => {
    if (res?.is_resetted) {
      setSnackBar({
        message: 'Success',
        variant: 'success',
      });
      handleCloseChangePass();
      handleOpenCompleteReset();
    }
  };

  const onError = () => {
    setSnackBar({
      message: 'Error changing password, try again.',
      variant: 'error',
    });
  };

  const handleSubmit = async event => {
    event.preventDefault();
    const payload = {
      reset_password_otp_uuid: uuid,
      password: formState.values.new_password,
      confirm_password: formState.values.confirm_password,
    };
    //setLoading(true);
    try {
      const res = await USERS_API.resetPassword(payload);
      onSuccess(res);
    } catch (e) {
      onError(e);
    }
  };

  const hasError = field =>
    !!(formState.touched[field] && formState.errors[field]);

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(prevFormState => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  const renderNewPasswordForm = () => {
    return (
      <form onSubmit={handleSubmit} className={classes.root}>
        <Typography className={classes.forgotLabel}>
          Enter your new password and click the Submit button
        </Typography>
        <Typography className={classes.label}>New Password</Typography>
        <TextField
          name="new_password"
          placeholder="New Password"
          className={classes.input}
          error={hasError('new_password')}
          helperText={
            hasError('new_password') ? formState.errors.new_password[0] : null
          }
          type="password"
          onChange={handleChange}
          value={formState.values.new_password || ''}
          fullWidth
          variant="outlined"
        />
        <Typography className={classes.label}>Re-Type Password</Typography>
        <TextField
          name="confirm_password"
          placeholder="Confirm Password"
          className={classes.input}
          error={hasError('confirm_password')}
          helperText={
            hasError('confirm_password')
              ? formState.errors.confirm_password[0]
              : null
          }
          type="password"
          onChange={handleChange}
          value={formState.values.confirm_password || ''}
          fullWidth
          variant="outlined"
        />

        <Button
          type="submit"
          color="primary"
          variant="contained"
          disabled={!formState.isValid}
          className={classes.resetPasswordButton}
          fullWidth
        >
          Submit
        </Button>

        <Button
          className={classes.resendButton}
          color="primary"
          onClick={handleClosePass}
        >
          Cancel
        </Button>
      </form>
    );
  };

  return <>{renderNewPasswordForm()}</>;
};

export default NewPasswordForm;
