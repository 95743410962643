import React from 'react';

const WalletContext = React.createContext({
  wallet: {},
  provider: {},
  validNetwork: true,
  loadWeb3Modal: () => {},
  logoutOfWeb3Modal: () => {},
});

export default WalletContext;
