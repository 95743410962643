import React from 'react';
import validate from 'validate.js';
import { Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { createBrowserHistory } from 'history';
import { Button } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import Providers from 'providers';

import theme from 'theme';
import SnackbarService from 'services/snackbar';
import { withStore } from 'react-context-hook';
import { getInitialState, storeConfig } from 'store';
import validators from 'common/validators';
import Routes from './Routes';
import './assets/css/index.css';

validate.validators = {
  ...validate.validators,
  ...validators,
};

const notistackRef = React.createRef();
const browserHistory = createBrowserHistory();
const queryClient = new QueryClient();

// eslint-disable-next-line no-undef
const nodeEnv = process.env.NODE_ENV;

const App = () => {
  const onClickDismiss = key => () => {
    // alert(key);
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          ref={notistackRef}
          maxSnack={5}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          action={key => (
            <Button style={{ color: 'white' }} onClick={onClickDismiss(key)}>
              Close
            </Button>
          )}
          preventDuplicate
          autoHideDuration={5000}
        >
          <Router history={browserHistory}>
            <Providers>
              <Routes />
            </Providers>
          </Router>
          <SnackbarService />
        </SnackbarProvider>
      </ThemeProvider>
      {nodeEnv === 'development' ? (
        <ReactQueryDevtools initialIsOpen={false} />
      ) : null}
    </QueryClientProvider>
  );
};

export default withStore(App, getInitialState(), storeConfig);
