import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  root: {
    zIndex: 9998,
    color: '#fff',
  },
  text: {
    marginLeft: theme.spacing(3),
    color: theme.palette.white,
  },
  connect: {
    minHeight: 177,
    borderRadius: 15,
    fontSize: 18,
    fontWeight: 'bold',
    border: '3px dashed #FFFFFF',
    width: 520,
    marginTop: 50,
    marginRight: 50,
  },
  connectContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  connectIcon: {
    margin: 'auto',
    width: 52,
    height: 52,
  },
  connected: {
    minHeight: 177,
    borderRadius: 15,
    paddingTop: 16,
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.background.paper,
    width: 520,
    marginRight: 50,
    marginTop: 50,
  },
  button: {
    fontWeight: 'bold',
    borderRadius: 15,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    padding: '0 16px',
    color: theme.palette.text.primary,
    fontWeight: 'bold',
  },
  address: {
    padding: '0 16px',
    color: theme.palette.text.primary,
  },
}));
