import { get } from 'lodash';
import { requestAPI } from 'services/request';

const loginRequestAPI = async ({ payload }) =>
  requestAPI('POST', '/transactions/login/request', payload, { auth: false });

//===========================================================================
const request = async payload => {
  const res = await loginRequestAPI({ payload });
  return get(res, 'data', {});
};

export const TRANSACTIONS_LOGIN_API = {
  request,
};
