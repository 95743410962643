import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import useStyles from './styles';

const NotFound = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container justify="center" spacing={4} className={classes.grid}>
        <Grid item>
          <div className={classes.content}>
            <img
              alt="Under development"
              className={classes.image}
              src="/images/404.png"
            />
            <Typography variant="subtitle2">
              You don't know where you are? Neither do we.
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default NotFound;
