import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.default,
    borderRight: '2px solid #000',
  },
  mobileDrawer: {
    width: 200,
  },
  desktopDrawer: {
    width: 200,
    top: 65,
    height: 'calc(100% - 65px)',
  },
  navigation: {
    overflow: 'auto',
    padding: theme.spacing(0, 2, 2, 2),
    flexGrow: 1,
  },
  profile: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05)',
    border: '1px solid #eeeeee',
    borderRadius: 5,
  },
  details: {
    marginLeft: theme.spacing(2),
  },
  subheader: {
    color: theme.palette.primary.main,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

export default useStyles;
