/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useGetAndSet } from 'react-context-hook';
import { useSnackbar } from 'notistack';
import { STORE } from 'store';

const SnackbarService = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [snackbar, setSnackbar] = useGetAndSet(STORE.SNACKBAR);

  useEffect(() => {
    if (snackbar.message && !snackbar.done) {
      enqueueSnackbar(snackbar.message, {
        variant: snackbar.variant || 'default',
      });
      setSnackbar({ ...snackbar, done: true });
    }
  }, [snackbar]);

  return <div id="snackbar-registry" />;
};

export default SnackbarService;
