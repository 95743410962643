import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  root: {
    margin: '24px 0',
    padding: '0 58px',
    textAlign: 'center',
  },
  logo: {
    height: 80,
    marginTop: 24,
    marginBottom: 16,
  },
  input: {
    backgroundColor: theme.palette.dark,
    width: 385,
    height: 60,
    borderRadius: 10,
    margin: 'auto',
    marginBottom: 30,
  },
  button: {
    borderRadius: 4,
    marginTop: 16,
  },
  closeButton: {
    borderRadius: 4,
    marginTop: 16,
    marginLeft: 8,
  },
  changePassButton: {
    color: theme.palette.white,
  },
  dialog: {
    borderRadius: 20,
    padding: '4%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: theme.palette.darkBackground,
  },
  btnFull: {
    display: 'flex',
    width: '100%',
  },
  paper: {
    backgroundColor: theme.palette.darkBackground,
  },
  label: {
    color: theme.palette.secondary.dark,
    fontSize: 18,
    textAlign: 'left',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 385,
    marginBottom: 24,
  },
  logInButton: {
    width: 385,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 10,
    height: 60,
  },
  resetPasswordButton: {
    width: 385,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 10,
    height: 60,
  },
  createAccountButton: {
    color: theme.palette.white,
    marginTop: 24,
  },
  resendButton: {
    color: theme.palette.white,
    marginTop: 24,
  },
  forgotLabel: {
    fontSize: 18,
    color: theme.palette.white,
    marginTop: 28,
    marginBottom: 40,
  },
}));
