import axios from 'axios';
import { getRefreshToken, setCookie, forceLogout } from 'store';
import { SKIP_INTERCEPTED_ERRORS } from 'constants/appConstants';

let isRefreshing = false;
let failedQueue = [];

// eslint-disable-next-line no-undef
const baseURL = process.env.REACT_APP_POLKER_SERVER_URL;

const fullUrl = (url, params = '') => {
  return `${baseURL}${url}${params}`;
};

const processQueue = (error, token = null) => {
  failedQueue.forEach(cb => {
    if (error) {
      cb.reject(error);
    } else {
      cb.resolve(token);
    }
  });

  failedQueue = [];
};

const instance = axios.create({ baseURL });

instance.interceptors.response.use(
  response => response,
  error => {
    const { config, response } = error;

    if (!response) {
      // Network Error
      return Promise.reject(error);
    }

    const originalRequest = config;
    const refreshToken = getRefreshToken();
    const URL = fullUrl('/tokens/refresh');
    const payload = { refresh_token: refreshToken };

    if (
      error.response.status === 401 &&
      !SKIP_INTERCEPTED_ERRORS.includes(error.response.data) &&
      !originalRequest._retry
    ) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then(token => {
            originalRequest.headers['Authorization'] = 'Bearer ' + token;
            return instance(originalRequest);
          })
          .catch(err => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise((resolve, reject) => {
        axios
          .post(URL, payload)
          .then(({ data }) => {
            const { token, refresh_token } = data;
            setCookie('jwtToken', token);
            setCookie('refreshToken', refresh_token);
            originalRequest.headers['Authorization'] = 'Bearer ' + token;
            processQueue(null, token);
            resolve(axios(originalRequest));
          })
          .catch(err => {
            processQueue(err, null);
            forceLogout();
            reject(err);
          })
          .then(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(error);
  },
);

export default instance;
