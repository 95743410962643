import { USERS_API } from 'apis/user';
import { TOKENS_API } from 'apis/tokens';
import { setCookie, forceLogout, STORE } from 'store';
import { useGetAndSet } from 'react-context-hook';

const useProvideAuth = () => {
  const [user, setUser] = useGetAndSet(STORE.USER_DETAILS);

  const signIn = async payload => {
    const res = await TOKENS_API.request(payload);
    const { token, refresh_token } = res;
    if (token && refresh_token) {
      setCookie('jwtToken', token);
      setCookie('refreshToken', refresh_token);
      const data = await USERS_API.me({ token });
      setUser(data);
    }
  };

  const signOut = async payload => {
    await TOKENS_API.revoke(payload);
    setUser({});
    forceLogout();
  };

  return {
    user,
    signIn,
    signOut,
  };
};

export default useProvideAuth;
