import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Typography, Link } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    padding: theme.spacing(3),
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
  },
  light: {
    color: theme.palette.text.contrastText,
  },
}));

const Footer = props => {
  const { className, light, ...rest } = props;
  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className, {
        [classes.light]: light,
      })}
    >
      <Typography color="inherit" variant="caption">
        <Link
          component="a"
          href="https://polker.io/"
          target="_blank"
          color="inherit"
        >
          Polker
        </Link>{' '}
        &copy;{' 2021 All rights reserved. '}
      </Typography>
      <Typography color="inherit" variant="caption">
        {/* eslint-disable-next-line no-undef */}v
        {process.env.REACT_APP_VERSION}
      </Typography>
    </div>
  );
};

Footer.propTypes = {
  light: PropTypes.bool,
  className: PropTypes.string,
};

export default Footer;
