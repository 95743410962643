export const LOGGED_IN = 'LOGGED_IN';

export const LOGGED_OUT = 'LOGGED_OUT';

export const DEFAULT = 'default';

export const SKIP_INTERCEPTED_ERRORS = [];

export const onError = e => {
  throw e;
};
