import React, { useState } from 'react';
import OtpInput from 'react-otp-input';
import useAuth from 'hooks/useAuth';
import { STORE } from 'store';
import { TOKENS_API } from 'apis/tokens';
import { useSetStoreValue } from 'react-context-hook';
import { Button, Typography } from '@material-ui/core';
import useStyles from '../components/LoginForm/styles';
import { TRANSACTIONS_LOGIN_API } from 'apis/Transactions/login';

const OTPModal = props => {
  const { formState, uuid, onSuccess, onClose } = props;
  const [resetUUID, setResetUUID] = useState(uuid);
  const classes = useStyles();
  const [OTP, setOTP] = useState('');
  const setSnackBar = useSetStoreValue(STORE.SNACKBAR);
  const auth = useAuth();

  const onError = () => {
    setSnackBar({
      message: 'Invalid one-time-password, try again.',
      variant: 'error',
    });
  };

  const handleChange = val => setOTP(val);

  const handleSubmitOTP = async () => {
    try {
      const payload = {
        email: formState.values.email,
        password: formState.values.password,
        login_transaction_otp_uuid: resetUUID,
        login_transaction_otp: OTP,
      };
      await auth.signIn(payload);
      onSuccess();
    } catch (e) {
      console.log('ERROR: ', e);
      onError();
    }
  };

  const handleResend = async event => {
    event.preventDefault();
    const payload = {
      email: formState.values.email,
      password: formState.values.password,
      application: 'kyc-portal',
    };
    try {
      const res = await TRANSACTIONS_LOGIN_API.request(payload);
      setResetUUID(res.login_transaction_otp_uuid);
    } catch (e) {
      onError(e);
    }
  };

  const renderLoginOTP = () => {
    return (
      <div className={classes.root}>
        <Typography className={classes.forgotLabel}>
          Please check your email, and type the 6 digit code below:
        </Typography>
        <OtpInput
          value={OTP}
          numInputs={6}
          onChange={handleChange}
          inputStyle={{
            height: 60,
            width: 55,
            margin: 4,
            fontSize: 20,
            outline: 'none',
            color: '#ffffff',
            borderRadius: 10,
            backgroundColor: '#150e12',
            border: '1px solid #150e12',
          }}
          isInputNum
          className={classes.center}
        />

        <Button
          className={classes.resendButton}
          color="primary"
          onClick={handleResend}
        >
          Resend Code
        </Button>

        <Button
          type="submit"
          color="primary"
          variant="contained"
          className={classes.resetPasswordButton}
          fullWidth
          onClick={handleSubmitOTP}
        >
          SUBMIT
        </Button>

        <Button
          className={classes.resendButton}
          color="primary"
          onClick={onClose}
        >
          Cancel
        </Button>
      </div>
    );
  };

  return <>{renderLoginOTP()}</>;
};

export default OTPModal;
