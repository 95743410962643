import React from 'react';
import { Backdrop, Typography, useMediaQuery } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import useStyles from './styles';
import { useTheme } from '@material-ui/styles';
import clsx from 'clsx';

const Loading = ({ loading, loadingText, className, iconOnly = false }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={clsx(classes.root, className)}>
      {iconOnly ? (
        <CircularProgress
          size={18}
          color="secondary"
          className={classes.circle}
        />
      ) : (
        <>
          <CircularProgress
            size={18}
            color="secondary"
            className={classes.circle}
          />
          <Typography className={classes.text} variant="h6">
            {loadingText}
          </Typography>
        </>
      )}
    </div>
  );
};

export default Loading;
