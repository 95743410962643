import React, { useEffect } from 'react';
import {
  ETHEREUM_MAINNET_ID,
  ETHEREUM_TESTNET_ID,
} from 'constants/networkConstants';
import { get } from 'lodash';
import { STORE } from 'store';
import WalletContext from 'contexts/WalletContext';
import { useGetAndSet, useSetStoreValue } from 'react-context-hook';

const WalletProvider = props => {
  const { children, provider, loadWeb3Modal, logoutOfWeb3Modal } = props;
  const setSnackbar = useSetStoreValue(STORE.SNACKBAR);
  const [wallet, setWallet] = useGetAndSet(STORE.WALLET);
  const [validNetwork, setValidNetwork] = useGetAndSet(STORE.VALID_NETWORK);
  const NETWORK_ID =
    // eslint-disable-next-line no-undef
    process.env.REACT_APP_ENV === 'production'
      ? ETHEREUM_MAINNET_ID
      : ETHEREUM_TESTNET_ID;

  useEffect(() => {
    // const invalidNetwork = () => {
    //   setSnackbar({
    //     variant: 'error',
    //     message: 'Network is invalid! Please change to ethereum network.',
    //   });
    // };
    const checkNetworkValidity = async () => {
      const network = await provider.getNetwork();
      const networkId = network?.chainId || 0;
      const isValid = NETWORK_ID === networkId;
      if (!isValid) {
        // invalidNetwork();
        await setValidNetwork(false);
        // await logoutOfWeb3Modal();
      } else {
        await setValidNetwork(true);
      }
      return [isValid, networkId];
    };

    const onLogout = async () => {
      setValidNetwork(true);
      await logoutOfWeb3Modal();
      window.location.reload();
    };

    const onAccountChanged = async data => {
      if (data.length) {
        await onInitAccount();
      } else {
        await onLogout();
      }
    };

    const onNetworkChanged = async () => {
      await onLogout();
    };

    const onInitAccount = async () => {
      const [isValid, networkId] = await checkNetworkValidity();
      if (isValid) {
        const accounts = await provider.listAccounts();
        const wallet = get(accounts, '0', '');
        setWallet({ wallet, networkId });
      }
    };

    if (provider) {
      const p = provider.provider;
      onInitAccount();

      // Subscribe to accounts change
      p.on('accountsChanged', onAccountChanged);

      // Subscribe to network change
      p.on('chainChanged', onNetworkChanged);

      p.on('connect', data => {
        // console.log('Connect', { data });
      });

      // Subscribe to provider disconnection
      p.on('disconnect', () => {
        setWallet({});
      });
    }
  }, [provider]);

  return (
    <WalletContext.Provider
      value={{
        wallet,
        provider,
        validNetwork,
        loadWeb3Modal,
        logoutOfWeb3Modal,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export default WalletProvider;
