import { get } from 'lodash';
import { requestAPI } from 'services/request';

const browseAPI = async ({ ...rest }) =>
  requestAPI('GET', '/wallets/browse', {}, { ...rest });

const connectAPI = async ({ payload, ...rest }) =>
  requestAPI('POST', '/wallets/connect', payload, { ...rest });

const depositAPI = async ({ payload, ...rest }) =>
  requestAPI('POST', '/wallets/deposit', payload, { ...rest });

const syncAPI = async ({ ...rest }) =>
  requestAPI('POST', '/wallets/sync', {}, { ...rest });

const withdrawAPI = async ({ payload, ...rest }) =>
  requestAPI('POST', '/wallets/withdraw', payload, { ...rest });

const browse = async () => {
  const res = await browseAPI({});
  return get(res, 'data', {});
};

const connect = async payload => {
  const res = await connectAPI({ payload });
  return get(res, 'data', {});
};

const deposit = async payload => {
  const res = await depositAPI({ payload });
  return get(res, 'data', {});
};

const sync = async () => {
  const res = await syncAPI({});
  return get(res, 'data', {});
};

const withdraw = async payload => {
  const res = await withdrawAPI({ payload });
  return get(res, 'data', {});
};

export const WALLETS_API = {
  browse,
  connect,
  deposit,
  sync,
  withdraw,
};
