import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import { URL_HELPERS } from 'helpers/url';

export default [
  {
    id: 1,
    items: [
      {
        id: 1,
        title: 'Home',
        href: URL_HELPERS.home,
        icon: HomeOutlinedIcon,
      },
      {
        id: 2,
        title: 'Buy Chips',
        href: URL_HELPERS.buy,
        icon: CreditCardIcon,
      },
      {
        id: 3,
        title: 'Withdraw',
        href: URL_HELPERS.withdraw,
        icon: LocalAtmIcon,
      },
      {
        id: 4,
        title: 'Wallets',
        href: URL_HELPERS.wallet,
        icon: AccountBalanceWalletIcon,
      },
    ],
  },
];
