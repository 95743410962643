import {
  ETHEREUM_MAINNET_ID,
  ETHEREUM_TESTNET_ID,
  // BSC_MAINNET_ID,
  // BSC_TESTNET_ID,
} from 'constants/networkConstants';
import { ABI } from 'contracts/artifacts';

const defaultContract = {
  serverAddress: '',
  pkr: {
    abi: [],
    address: '',
  },
  stakePKR: {
    abi: [],
    address: '',
  },
  countdownUrl: 'https://ropsten.etherscan.io/block/countdown',
};

export const SMART_CONTRACTS = {
  [ETHEREUM_MAINNET_ID]: {
    ...defaultContract,
  },
  [ETHEREUM_TESTNET_ID]: {
    serverAddress: '0x35149094e0f36fc41ce95520CDE7A168108e2468',
    pkr: {
      abi: ABI.PKRTokenERC20,
      address: '0x453d8822fa6ddc7fbffe081633fd2f1c647476d5',
    },
    stakePKR: {
      abi: ABI.PKRStaking,
      address: process.env.REACT_APP_ETH_STAKING_SMART_CONTRACT,
    },
    countdownUrl: 'https://ropsten.etherscan.io/block/countdown',
  },
  // [BSC_MAINNET_ID]: {
  //   ...defaultContract,
  // },
  // [BSC_TESTNET_ID]: {
  //   serverAddress: '0x35149094e0f36fc41ce95520CDE7A168108e2468',
  //   pkr: {
  //     abi: ABI.PKRTokenBEP20,
  //     address: '0x18c517675a52ca81d543cfd19231201cea51093d',
  //   },
  //   stakePKR: {
  //     abi: ABI.PKRStaking,
  //     address: '0xcAb3Ac9e05c4b1F3E2431B056F1d93929a840FBd',
  //   },
  // },
};
