import React, { useState, useContext, useEffect } from 'react';
import {
  Button,
  Modal,
  Input,
  Grid,
  Link,
  Typography,
  IconButton,
  InputAdornment,
  useMediaQuery,
} from '@material-ui/core';
import clsx from 'clsx';
import { get } from 'lodash';
import { STORE } from 'store';
import Loading from 'components/Loading';
import Paper from '@material-ui/core/Paper';
import { LOGIC_HELPERS } from 'helpers/logic';
import { ETHERS_SERVICE } from 'services/ethers';
import ClearIcon from '@material-ui/icons/Clear';
import Checkbox from '@material-ui/core/Checkbox';
import StakingContext from 'contexts/StakingContext';
import { withStyles } from '@material-ui/core/styles';
import { useSetStoreValue } from 'react-context-hook';
import ForwardIcon from '@material-ui/icons/ForwardRounded';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import TimerIcon from '@material-ui/icons/Timer';
import {
  VALID_NETWORK_IDS_DEV,
  VALID_NETWORK_IDS_PROD,
} from 'constants/networkConstants';
import useStyles from './styles';
import { useTheme } from '@material-ui/styles';
import { STRING_HELPERS } from 'helpers/stringAdditions';

const Stake = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [showStakeModal, setShowStakeModal] = useState(false);
  const [stakeAmount, setStakeAmount] = useState(0);
  const [unstakeAmount, setUnstakeAmount] = useState(0);
  const [showUnstakeWarning, setShowUnstakeWarning] = useState(false);
  const [showConfirmDisconnect, setShowConfirmDisconnect] = useState(false);
  const [balance, setBalance] = useState('0.0');
  const [showUnstakeModal, setShowUnstakeModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [confirmTerms, setConfirmTerms] = useState(false);
  const [enabledPool, setEnabledPool] = useState(true);
  const [poolLoading, setPoolLoading] = useState(true);
  const [totalStakers, setTotalStakers] = useState(0);
  const setSnackbar = useSetStoreValue(STORE.SNACKBAR);
  const [poolInfo, setPoolInfo] = useState({
    countdownUrl: '',
    totalStaked: 0,
    allowance: 0,
    endBlock: 0,
    endsIn: 0,
    earned: 0,
    staked: 0,
    apr: 0,
  });
  const theme = useTheme();
  const customMdDown = useMediaQuery('@media (max-width: 1655px)');
  const VALID_NETWORK_IDS =
    // eslint-disable-next-line no-undef
    process.env.REACT_APP_ENV === 'production'
      ? VALID_NETWORK_IDS_DEV
      : VALID_NETWORK_IDS_PROD;
  const { provider, wallet, validNetwork, loadWeb3Modal, logoutOfWeb3Modal } =
    useContext(StakingContext);
  const address = get(wallet, 'wallet', '').toLowerCase();
  const networkId = get(wallet, 'networkId', 0);
  const maxValue = balance;
  const displayAddress = STRING_HELPERS.renderWalletAddress(address);
  const PurpleCheckbox = withStyles({
    root: {
      color: '#8746f4',
      '&$checked': {
        color: '#8746f4',
      },
    },
    checked: {},
  })(props => <Checkbox color="default" {...props} />);

  useEffect(() => {
    if (address) {
      setRefresh(true);
    }
  }, [address]);

  useEffect(() => {
    const fetchPoolInfo = async () => {
      try {
        setPoolLoading(true);
        const [, , ethersHelper] = ETHERS_SERVICE(provider, address, networkId);
        const allowance = await ethersHelper.methods.getStakingAllowance(
          address,
        );
        const totalStaked = await ethersHelper.methods.getTotalStaked();
        const staked = await ethersHelper.methods.getUserInfo(address);
        const earned = await ethersHelper.methods.getPendingReward(address);
        const endsIn = await ethersHelper.methods.getRemainingBlocks();
        const endBlock = await ethersHelper.methods.getBonusEndBlock();
        const apr = await ethersHelper.methods.getAPR();
        const countdownUrl = await ethersHelper.methods.getBlockCountdown(
          endBlock,
        );
        setPoolInfo({
          apr,
          totalStaked,
          staked,
          earned,
          endsIn,
          endBlock,
          allowance,
          countdownUrl,
        });

        if (parseFloat(allowance)) {
          setEnabledPool(true);
        } else {
          setEnabledPool(false);
        }
        setPoolLoading(false);
        setConfirmTerms(false);
      } catch (e) {
        console.log('ERROR: ', e);
      }
    };

    const fetchUserBalance = async () => {
      const [, , ethersHelper] = ETHERS_SERVICE(provider, address, networkId);
      const res = await ethersHelper.methods.checkPKRBalance();
      setBalance(res);
    };

    const fetchTotalStaker = async () => {
      const [, , ethersHelper] = ETHERS_SERVICE(provider, address, networkId);
      const res = await ethersHelper.methods.getTotalStakers();
      setTotalStakers(res);
    };

    const startFetching = async () => {
      const network = await provider.getNetwork();
      const networkId = network?.chainId || 0;
      const isValid = VALID_NETWORK_IDS.includes(networkId);
      const initialized = LOGIC_HELPERS.ifElse(
        [isValid, validNetwork],
        true,
        false,
      );
      if (initialized && refresh) {
        await fetchPoolInfo();
        await fetchTotalStaker();
        await fetchUserBalance();
        setRefresh(false);
      }
    };

    if (provider) {
      startFetching();
    }
  }, [provider, validNetwork, networkId, address, refresh]);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleCloseModal = () => {
    setShowStakeModal(false);
    setShowUnstakeModal(false);
    setShowConfirmDisconnect(false);
    setStakeAmount(0);
    setUnstakeAmount(0);
  };

  const handleCloseWarning = () => {
    setShowUnstakeWarning(false);
    setShowUnstakeModal(true);
  };

  const handleOpenWarning = () => {
    setShowUnstakeModal(false);
    setShowUnstakeWarning(true);
  };

  const onMaxInput = () => {
    setStakeAmount(balance);
  };

  const onMaxWithdraw = () => {
    const { staked } = poolInfo;
    setUnstakeAmount(staked);
  };

  const handleChangeStake = e => {
    const pattern =
      /^((\d{1,3})([0-9]{3}){0,1}|(\d{1})([0-9]{3}){0,2}|(\d{1,7}))(\.\d{1,18})?$/i;
    const value = e.target.value;
    const match = value.match(pattern);

    if (match) {
      if (parseFloat(match) > 0 && parseFloat(match) <= parseFloat(maxValue)) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    } else {
      setDisabled(true);
    }

    setStakeAmount(value);
  };

  const handleChangeUnstake = e => {
    const pattern =
      /^((\d{1,3})([0-9]{3}){0,1}|(\d{1})([0-9]{3}){0,2}|(\d{1,7}))(\.\d{1,18})?$/i;
    const value = e.target.value;
    const match = value.match(pattern);

    if (match) {
      if (parseFloat(match) > 0 && parseFloat(match) <= parseFloat(maxValue)) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    } else {
      setDisabled(true);
    }

    setUnstakeAmount(value);
  };

  const onShowStakeModal = () => {
    setShowStakeModal(true);
  };

  const onShowUnstakeModal = () => {
    setShowUnstakeModal(true);
  };

  const onSuccess = message => {
    setRefresh(true);
    setLoading(false);
    setShowStakeModal(false);
    setShowUnstakeModal(false);
    setShowUnstakeWarning(false);
    setSnackbar({
      variant: 'success',
      message: message || 'Success!',
    });
  };

  const onError = message => {
    setLoading(false);
    setSnackbar({
      variant: 'error',
      message: message || 'Success!',
    });
  };

  const onEnablePool = async () => {
    try {
      const [, , ethersHelper] = ETHERS_SERVICE(provider, address, networkId);
      setLoading(true);
      await ethersHelper.methods.approveStakingPool();
      setEnabledPool(true);
      onSuccess('Pool enabled successfully!');
    } catch (e) {
      onError('Pool not enabled! Try again later.');
    }
  };

  const onStakePKR = async () => {
    try {
      const [, , ethersHelper] = ETHERS_SERVICE(provider, address, networkId);
      setLoading(true);
      await ethersHelper.methods.stakePKR(stakeAmount);
      onSuccess('Staked PKR successfully!');
    } catch (e) {
      console.log({ e });
      if (e.code === 4001) {
        onError('User has cancelled/rejected the transaction.');
      } else {
        onError('Staking PKR failed! Try again later.');
      }
    }
  };

  const onDisconnect = async () => {
    await logoutOfWeb3Modal();
    setShowConfirmDisconnect(false);
  };

  const handleDisconnect = async () => {
    setShowConfirmDisconnect(true);
  };

  const onWithdrawPKR = collect => async () => {
    const amountToWidthdraw = LOGIC_HELPERS.ifElse(collect, 0, unstakeAmount);
    const successMessage = LOGIC_HELPERS.ifElse(
      collect,
      'Reward has been collected successfully!',
      'PKR has been withdrawn successfully!',
    );
    const errorMessage = LOGIC_HELPERS.ifElse(
      collect,
      'Collecting reward failed! Try again later.',
      'Failed to withdraw PKR! Try again later.',
    );
    try {
      const [, , ethersHelper] = ETHERS_SERVICE(provider, address, networkId);
      setLoading(true);
      await ethersHelper.methods.withdrawStake(amountToWidthdraw);
      onSuccess(successMessage);
    } catch (e) {
      if (e.code === 4001) {
        onError('User has cancelled/rejected the transaction.');
      } else {
        onError(errorMessage);
      }
    }
  };

  const onConfirmTerms = () => {
    setConfirmTerms(!confirmTerms);
  };

  const renderAssetHeader = () => (
    <Grid item className={clsx(classes.headerItem, classes.spacingRight)}>
      <Typography
        variant="body1"
        color="textPrimary"
        className={classes.stakeHeader}
      >
        STAKERS
      </Typography>
      <Typography
        color="textPrimary"
        variant="h5"
        className={classes.stakeHeaderSubTitle}
      >
        {totalStakers}
      </Typography>
    </Grid>
  );

  const renderAPR = () => {
    const { apr } = poolInfo;
    const displayAPR = LOGIC_HELPERS.ifElse(apr === 'N/A', apr, `${apr}%`);

    return (
      <div className={clsx(classes.headerItem, classes.spacingRight)}>
        <Typography color="textSecondary" className={classes.stakeHeaderTitle}>
          APR
        </Typography>
        <Typography
          variant="h5"
          color="textPrimary"
          className={classes.stakeHeaderSubTitle}
        >
          {displayAPR}
        </Typography>
      </div>
    );
  };

  const renderTotalStaked = () => {
    const { totalStaked } = poolInfo;
    const displayStaked = Math.floor(totalStaked * 100000) / 100000;
    return (
      <div className={clsx(classes.headerItem, classes.spacingRight)}>
        <Typography color="textSecondary" className={classes.stakeHeaderTitle}>
          TOTAL PKR STAKED
        </Typography>
        <Typography
          variant="h5"
          color="textPrimary"
          className={classes.stakeHeaderSubTitle}
        >
          {displayStaked}
        </Typography>
      </div>
    );
  };

  const renderBlockCountdown = () => {
    const { endBlock, countdownUrl } = poolInfo;

    if (!endBlock || !countdownUrl) {
      return null;
    }

    return (
      <a
        target="_blank"
        href={countdownUrl}
        rel="noopener noreferrer"
        className={classes.countdown}
      >
        <TimerIcon />
      </a>
    );
  };

  const renderEndsIn = () => {
    const { endsIn, endBlock } = poolInfo;
    const displayText = LOGIC_HELPERS.ifElse(endsIn, endsIn, '-');
    const block = LOGIC_HELPERS.pluralise('block', endsIn, true);

    return (
      <div className={clsx(classes.headerItem, classes.spacing)}>
        <Typography color="textSecondary" className={classes.stakeHeaderTitle}>
          ENDS IN (BLOCKS)
        </Typography>
        <div className={classes.endsBlock}>
          <Typography
            variant="h5"
            color="textPrimary"
            className={classes.stakeHeaderSubTitle}
          >
            {displayText}
          </Typography>
          {renderBlockCountdown()}
        </div>
      </div>
    );
  };

  const renderStakeHeader = () => (
    <>
      {renderAssetHeader()}
      {renderAPR()}
      {renderTotalStaked()}
      {renderEndsIn()}
    </>
  );

  const renderEarned = () => {
    const { earned } = poolInfo;
    const disabledBtn = LOGIC_HELPERS.ifElse(parseFloat(earned), false, true);
    const displayEarned = Math.floor(earned * 100000) / 100000;

    return (
      <Grid container className={classes.paper}>
        <Grid item className={classes.titleHeader}>
          <Typography
            color="textSecondary"
            variant="caption"
            className={classes.paperHeader}
          >
            PKR EARNED
          </Typography>
          <Typography color="textPrimary" variant="h5">
            {displayEarned}
          </Typography>
        </Grid>
        <Grid item className={classes.paperStakeContent}>
          {loading ? (
            <Loading loading={loading} loadingText="Please wait..." />
          ) : (
            <Button
              className={classes.startStakingBtn}
              onClick={onWithdrawPKR(true)}
              disabled={disabledBtn}
              color="textPrimary"
            >
              Collect
            </Button>
          )}
        </Grid>
      </Grid>
    );
  };

  const renderEnablePool = () => {
    return (
      <Grid container className={classes.enablePoolBtnContainer}>
        {loading ? (
          <Loading
            loading={loading}
            loadingText="Please wait..."
            className={classes.loadingPool}
          />
        ) : (
          <Button
            className={classes.enablePoolBtn}
            disabled={!confirmTerms}
            onClick={onEnablePool}
          >
            Enable Pool
          </Button>
        )}

        <Grid container direction="row" className={classes.termsContainer}>
          <PurpleCheckbox
            checked={confirmTerms}
            onChange={onConfirmTerms}
            className={classes.cbox}
          />
          <Typography
            variant="inherit"
            color="textPrimary"
            className={classes.read}
          >
            I have read and agree to the&nbsp;
          </Typography>
          <Typography variant="inherit" className={classes.terms}>
            Terms and Conditions
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const renderStartStake = () => {
    const { staked } = poolInfo;
    const displayStaked = Math.floor(staked * 100000) / 100000;

    return (
      <Grid container className={classes.paper}>
        <Grid item className={classes.titleHeader}>
          <Typography variant="caption" className={classes.paperHeader}>
            PKR STAKED
          </Typography>
          <Typography variant="h5" color="textPrimary">
            {displayStaked}
          </Typography>
        </Grid>
        <Grid item className={classes.paperStakeContent}>
          <Button
            className={classes.startStakingBtn}
            color="textPrimary"
            onClick={onShowStakeModal}
          >
            Start Staking
          </Button>
        </Grid>
      </Grid>
    );
  };

  const renderStaking = () => {
    const { staked } = poolInfo;
    const displayStaked = Math.floor(staked * 100000) / 100000;

    return (
      <Grid container className={classes.paper}>
        <Grid item className={classes.titleHeader}>
          <Typography variant="caption" className={classes.paperHeader}>
            PKR STAKED
          </Typography>
          <Typography variant="h5" color="textPrimary">
            {displayStaked}
          </Typography>
        </Grid>
        <div className={classes.stakeButtonContainer}>
          <Button
            className={classes.stakeButton}
            color="textPrimary"
            onClick={onShowStakeModal}
            fullWidth
          >
            Stake More
          </Button>
          <Button
            className={classes.stakeButton}
            color="textPrimary"
            onClick={onShowUnstakeModal}
            fullWidth
          >
            Unstake
          </Button>
        </div>
      </Grid>
    );
  };

  const renderStakeModalContent = () => {
    const { endsIn } = poolInfo;
    const disabledBtn = LOGIC_HELPERS.ifElse(
      Math.ceil(stakeAmount) === 0 || stakeAmount === '' || endsIn === 0,
      true,
      false,
    );
    const displayPoolEnded = LOGIC_HELPERS.ifElse(endsIn === 0, true, false);
    return (
      <div className={classes.modalRoot}>
        <Paper elevation={0} className={classes.modalHeader}>
          <Typography className={classes.modalHeaderText}>STAKE</Typography>
          <ClearIcon
            className={classes.modalHeaderClose}
            onClick={handleCloseModal}
          />
        </Paper>
        <Typography
          className={classes.modalTitle}
          variant="caption"
          color="textPrimary"
        >
          Amount to stake:
        </Typography>
        <div className={classes.inputRoot}>
          <Input
            type="number"
            value={stakeAmount}
            aria-valuemax={balance}
            onChange={handleChangeStake}
            disableUnderline
            className={classes.input}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={onMaxInput}
                >
                  <Typography variant="subtitle2" className={classes.max}>
                    MAX
                  </Typography>
                </IconButton>
              </InputAdornment>
            }
          />
          <Typography className={classes.splitter}>|</Typography>
          <Typography variant="body2" className={classes.pkr}>
            PKR
          </Typography>
        </div>
        <Typography color="textPrimary" className={classes.balance}>
          Balance: <b>{balance}</b>
        </Typography>
        {loading ? (
          <Loading loading={loading} loadingText="Please wait..." />
        ) : (
          <>
            <Button
              color="textPrimary"
              onClick={onStakePKR}
              className={classes.confirmButton}
              disabled={disabledBtn}
            >
              Confirm
            </Button>
            {displayPoolEnded ? (
              <Typography className={classes.noteDeposit}>
                Note: End block has been met. Pool has ended.
              </Typography>
            ) : (
              <Typography className={classes.noteDeposit}>
                Note: You will withdraw your earned PKR when you deposit.
              </Typography>
            )}
          </>
        )}
      </div>
    );
  };

  const renderUnstakeWarning = () => {
    return (
      <div className={classes.modalRoot}>
        <Paper elevation={0} className={classes.modalHeader}>
          <Typography className={classes.modalHeaderText}>
            Unstaking Notification
          </Typography>
          <ClearIcon
            className={classes.modalHeaderClose}
            onClick={handleCloseWarning}
          />
        </Paper>
        <Typography
          className={classes.warningMessage}
          variant="caption"
          color="textPrimary"
        >
          Unstaking will also withdraw your PKR earned rewards. You are about to
          unstake <b>{unstakeAmount} PKR</b>. Do you want to proceed?
        </Typography>
        {loading ? (
          <Loading loading={loading} loadingText="Please wait..." />
        ) : (
          <Button
            color="textPrimary"
            onClick={onWithdrawPKR(false)}
            className={classes.confirmButton}
          >
            Proceed
          </Button>
        )}
      </div>
    );
  };

  const renderUnstakeModalContent = () => {
    const { staked } = poolInfo;
    const overUnstake = LOGIC_HELPERS.ifElse(
      parseFloat(unstakeAmount) > parseFloat(staked),
      true,
      false,
    );
    const disabledBtn = LOGIC_HELPERS.ifElse(
      Math.ceil(unstakeAmount) === 0 || unstakeAmount === '' || overUnstake,
      true,
      false,
    );
    return (
      <div className={classes.modalRoot}>
        <Paper elevation={0} className={classes.modalHeader}>
          <Typography className={classes.modalHeaderText}>UNSTAKE</Typography>
          <ClearIcon
            className={classes.modalHeaderClose}
            onClick={handleCloseModal}
          />
        </Paper>
        <Typography
          className={classes.modalTitle}
          variant="caption"
          color="textPrimary"
        >
          Amount to unstake:
        </Typography>
        <div className={classes.inputRoot}>
          <Input
            type="number"
            value={unstakeAmount}
            aria-valuemax={balance}
            onChange={handleChangeUnstake}
            disableUnderline
            className={classes.input}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={onMaxWithdraw}
                >
                  <Typography variant="subtitle2" className={classes.max}>
                    MAX
                  </Typography>
                </IconButton>
              </InputAdornment>
            }
          />
          <Typography className={classes.splitter}>|</Typography>
          <Typography variant="body2" className={classes.pkr}>
            PKR
          </Typography>
        </div>
        <Typography color="textPrimary" className={classes.balance}>
          Balance: <b>{staked}</b>
        </Typography>
        <Button
          color="textPrimary"
          onClick={handleOpenWarning}
          className={classes.confirmButton}
          disabled={disabledBtn}
        >
          Confirm
        </Button>
        {overUnstake ? (
          <Typography className={classes.noteDeposit}>
            Note: You are unstaking greater than your staked PKR
          </Typography>
        ) : (
          <Typography className={classes.noteDeposit}>
            Note: You will withdraw your earned PKR alongside your staked PKR.
          </Typography>
        )}
      </div>
    );
  };

  const renderConfirmDisconnect = () => {
    return (
      <div className={classes.modalRoot}>
        <Paper elevation={0} className={classes.modalHeader}>
          <Typography className={classes.modalHeaderText}>
            DISCONNECT
          </Typography>
          <ClearIcon
            className={classes.modalHeaderClose}
            onClick={handleCloseModal}
          />
        </Paper>
        <Typography
          className={classes.warningMessage}
          variant="caption"
          color="textPrimary"
        >
          You will disconnect your wallet. Do you want to proceed?
        </Typography>
        {loading ? (
          <Loading loading={loading} loadingText="Please wait..." />
        ) : (
          <Button
            color="textPrimary"
            onClick={onDisconnect}
            className={classes.confirmButton}
          >
            Proceed
          </Button>
        )}
      </div>
    );
  };

  const renderStakeContent = () => {
    const { staked } = poolInfo;
    const renderCard = LOGIC_HELPERS.ifElse(
      parseFloat(staked),
      renderStaking,
      renderStartStake,
    );

    return (
      <Grid container spacing={0} className={classes.stakeContent}>
        <Grid item className={classes.card}>
          {renderCard()}
        </Grid>
        <Grid item className={classes.card}>
          {renderEarned()}
        </Grid>
      </Grid>
    );
  };

  const renderStakeEntry = id => {
    const isExpanded = expanded === id;
    const displayText = LOGIC_HELPERS.ifElse(isExpanded, 'Hide', 'Details');
    const displayAdd = LOGIC_HELPERS.ifElse(
      customMdDown,
      displayAddress,
      address + ' -',
    );

    return (
      <Grid container className={classes.stakeMain}>
        <Grid container className={classes.content}>
          <Grid className={classes.header}>
            <Grid className={classes.headerRoot}>
              <Grid container className={classes.headerContent}>
                {renderStakeHeader()}
              </Grid>
            </Grid>
            <Grid container direction="row">
              <Typography
                className={classes.connectedWallet}
                variant="body1"
                color="textPrimary"
                gutterBottom
              >
                <b>CONNECTED WALLET:</b> {displayAdd}&nbsp;
              </Typography>
              <Link
                href="#"
                onClick={handleDisconnect}
                className={classes.disconnect}
                variant="body1"
                gutterBottom
              >
                DISCONNECT WALLET
              </Link>
            </Grid>
          </Grid>
          <Grid className={classes.headerRight}>
            {poolLoading ? (
              <Loading loading={loading} loadingText="Please wait..." />
            ) : enabledPool ? (
              renderStakeContent()
            ) : (
              renderEnablePool()
            )}
          </Grid>
        </Grid>
        <Modal open={showStakeModal} onClose={handleCloseModal}>
          {renderStakeModalContent()}
        </Modal>
        <Modal open={showUnstakeModal} onClose={handleCloseModal}>
          {renderUnstakeModalContent()}
        </Modal>
        <Modal open={showUnstakeWarning} onClose={handleCloseWarning}>
          {renderUnstakeWarning()}
        </Modal>
        <Modal open={showConfirmDisconnect} onClose={handleCloseModal}>
          {renderConfirmDisconnect()}
        </Modal>
      </Grid>
    );
  };

  const renderInvalidNetwork = () => (
    <div className={classes.note}>
      <Typography variant="h6" color="textPrimary" className={classes.title}>
        NETWORK INVALID!
      </Typography>
      <Typography variant="subtitle1" color="textPrimary">
        Please change to Ethereum network and connect your wallet again.
      </Typography>
    </div>
  );

  const renderConnectProvider = () => (
    <div className={classes.note}>
      <Typography variant="h6" color="textPrimary" className={classes.title}>
        WALLET PROVIDER NOT CONNECTED
      </Typography>
      <Typography variant="subtitle1" color="textPrimary">
        Connect to a wallet provider first.
      </Typography>
      <Button
        color="secondary"
        variant="contained"
        onClick={loadWeb3Modal}
        className={classes.lightButton}
        endIcon={<ForwardIcon />}
      >
        Connect wallet provider
      </Button>
    </div>
  );

  const renderContent = () => {
    if (!validNetwork) {
      return renderInvalidNetwork();
    }

    if (!provider) {
      return renderConnectProvider();
    }

    return <div className={classes.container}>{renderStakeEntry(1)}</div>;
  };

  return (
    <Grid className={classes.root}>
      <div className={classes.titleRoot}>
        {/* <Loading loading={loading} loadingText="Please wait..." /> */}
        <Typography variant="h6" color="textPrimary" className={classes.title}>
          PKR STAKING POOL
        </Typography>
      </div>
      {renderContent()}
    </Grid>
  );
};

export default Stake;
