import React, { useState } from 'react';
import { Button, Typography } from '@material-ui/core';
import OtpInput from 'react-otp-input';
import { USERS_API } from 'apis/user';
import { useSetStoreValue } from 'react-context-hook';
import { STORE } from 'store';
import useStyles from '../components/ResetPasswordForm/styles';

const OTPModal = props => {
  const { formState, uuid, onSuccess, onClose } = props;
  const [resetUUID, setResetUUID] = useState(uuid);
  const classes = useStyles();
  const [OTP, setOTP] = useState('');
  const setSnackBar = useSetStoreValue(STORE.SNACKBAR);

  const onError = () => {
    setSnackBar({
      message: 'Invalid one-time-password, try again.',
      variant: 'error',
    });
  };

  const handleChange = val => setOTP(val);

  const handleSubmitOTP = async () => {
    try {
      const payload = {
        reset_password_otp_uuid: resetUUID,
        reset_password_otp: OTP,
      };
      const res = await USERS_API.verifyOTPPassword(payload);
      onSuccess(res);
    } catch (e) {
      onError();
    }
  };

  const handleResend = async event => {
    event.preventDefault();
    const payload = {
      email: formState.values.email,
    };
    // setLoading(true);
    try {
      const res = await USERS_API.forgotPassword(payload);
      setResetUUID(res.reset_password_otp_uuid);
    } catch (e) {
      onError(e);
    }
  };

  const renderResetPasswordOTP = () => {
    return (
      <div className={classes.root}>
        <Typography className={classes.labelCenter}>
          PASSWORD RESET SECURITY CODE
        </Typography>
        <Typography className={classes.forgotLabel}>
          Please check your email, and type the 6 digit code below:
        </Typography>
        <OtpInput
          value={OTP}
          numInputs={6}
          onChange={handleChange}
          inputStyle={{
            height: 60,
            width: 55,
            margin: 4,
            fontSize: 20,
            outline: 'none',
            color: '#ffffff',
            borderRadius: 10,
            backgroundColor: '#150e12',
            border: '1px solid #150e12',
          }}
          isInputNum
          className={classes.center}
        />

        <Button
          className={classes.resendButton}
          color="primary"
          onClick={handleResend}
        >
          Resend Code
        </Button>

        <Button
          type="submit"
          color="primary"
          variant="contained"
          className={classes.resetPasswordButton}
          fullWidth
          onClick={handleSubmitOTP}
        >
          SUBMIT
        </Button>

        <Button
          className={classes.resendButton}
          color="primary"
          onClick={onClose}
        >
          Cancel
        </Button>
      </div>
    );
  };

  return <>{renderResetPasswordOTP()}</>;
};

export default OTPModal;
