import { get } from 'lodash';
import React, { useContext } from 'react';
import { useWallets } from 'hooks/wallets';
import { Grid, Typography } from '@material-ui/core';
import ConnectWalletButton from 'components/ConnectWallet';
import useStyles from './styles';

const Home = () => {
  const classes = useStyles();

  return <div className={classes.root}>Home</div>;
};

export default Home;
