import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { AppBar, Toolbar, Hidden, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import RouteToggle from 'components/RouteToggle';
import { URL_HELPERS } from 'helpers/url';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.border}`,
  },
  flexGrow: {
    flexGrow: 1,
  },
  buttons: {
    color: theme.palette.primary.main,
  },
  logoMobileLink: {
    flex: 1,
    textAlign: 'center',
  },
  logoMobile: {
    flex: 1,
    width: 80,
  },
  logo: {
    width: 90,
  },
}));

const Topbar = props => {
  const { className, onSidebarOpen } = props;
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)}>
      <Toolbar>
        <Hidden smDown>
          <RouterLink to={URL_HELPERS.home}>
            <img alt="Logo" className={classes.logo} src="/images/logo.png" />
          </RouterLink>
          <div className={classes.flexGrow} />
          {/* <RouteToggle /> */} {/* Update back */}
        </Hidden>
        <Hidden mdUp>
          <IconButton className={classes.buttons} onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
          <RouterLink to={URL_HELPERS.home} className={classes.logoMobileLink}>
            <img
              alt="Logo"
              className={classes.logoMobile}
              src="/images/logo.png"
            />
          </RouterLink>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  history: PropTypes.object,
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
};

export default withRouter(Topbar);
