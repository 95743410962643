import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    [theme.breakpoints.up('md')]: {
      padding: '50px 175px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '50px 80px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  '@media (min-width: 1850px)': {
    stakeMain: {
      height: 260,
    },
    stakeContent: {
      justifyContent: 'space-between',
    },
  },
  '@media (max-width: 1850px)': {
    stakeContent: {
      justifyContent: 'flex-end',
    },
    stakeMain: {
      height: 360,
    },
    card: {
      marginBottom: 4,
    },
  },
  '@media (max-width: 1239px)': {
    disconnect: {
      marginTop: 12,
    },
  },
  '@media (min-width: 1350px)': {
    disconnect: {
      marginTop: 55,
    },
  },
  '@media (min-width: 1101px)': {
    header: {
      paddingLeft: 30,
      paddingTop: 0,
    },
    enablePoolBtn: {
      marginLeft: 10,
      width: 350,
    },
    spacingRight: {
      width: 172,
      textAlign: 'left',
    },
    spacing: {
      width: 172,
      textAlign: 'left',
    },
    endsBlock: {
      justifyContent: 'left',
    },
  },
  '@media (max-width: 1100px)': {
    paperContent: {
      fontSize: 15,
    },
    stakeMain: {
      height: 680,
    },
    headerRight: {
      width: '70%',
      justifyContent: 'center',
    },
    stakeContent: {
      paddingRight: 0,
      justifyContent: 'center',
    },
    paperStartStake: {
      justifyContent: 'center',
    },
    enablePoolBtn: {
      marginTop: 12,
      marginLeft: 10,
      width: 270,
    },
    startStakingBtn: {
      width: 50,
      fontSize: 10,
    },
    stakeButtonContainer: {
      minWidth: 70,
      alignItems: 'center',
      flexDirection: 'row',
    },
    stakeButton: {
      minHeight: 46,
      fontSize: 10,
    },
    connectedWallet: {
      marginLeft: 0,
      textAlign: 'center',
      marginTop: 12,
      width: '100%',
    },
    termsContainer: {
      marginRight: 0,
      textAlign: 'center',
    },
    read: {
      fontSize: 12,
      lineHeight: '4em',
    },
    terms: {
      fontSize: 12,
      lineHeight: '4em',
    },
    content: {
      justifyContent: 'center',
    },
    disconnect: {
      textAlign: 'center',
      flex: 1,
    },
    cbox: {
      fontSize: 12,
    },
    card: {
      marginBottom: 4,
    },
    loadingPool: {
      marginRight: 0,
    },
    spacingRight: {
      width: '100%',
      textAlign: 'center',
    },
    spacing: {
      width: '100%',
      textAlign: 'center',
    },
    endsBlock: {
      justifyContent: 'center',
      paddingLeft: 24,
    },
  },
  '@media (max-width: 1099px)': {
    content: {
      flexDirection: 'row',
    },
    header: {
      paddingLeft: 0,
      paddingTop: 0,
    },
  },
  titleRoot: {
    paddingBottom: 32,
  },
  title: {
    fontWeight: 'bold',
  },
  headerItem: {
    height: 46,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  stakeMain: {
    borderRadius: 15,
    width: '100%',
    backgroundColor: theme.palette.dark,
  },
  stakeHeader: {
    fontSize: 14,
    color: theme.palette.secondary.dark,
  },
  earnHeader: {
    fontSize: 12,
  },
  stakeHeaderTitle: {
    fontSize: 14,
    color: theme.palette.secondary.dark,
  },
  header: {
    width: '50%',
    paddingTop: 5,
    alignItems: 'center',
    color: theme.palette.primary.main,
  },
  headerRight: {
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
    textAlign: 'end',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  headerRoot: {
    flex: 1,
    display: 'flex',
    marginRight: -8,
    alignItems: 'center',
  },
  headerContent: {
    flex: 1,
    display: 'flex',
    alignItems: 'flex-start',
  },
  details: {
    fontSize: 12,
    fontWeight: 'bold',
    width: 32,
  },
  expandIcon: {
    color: theme.palette.primary.main,
  },
  stakeContent: {
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0,
    },
    paddingRight: 30,
    display: 'flex',
    flexDirection: 'row',
  },
  paper: {
    display: 'flex',
    border: '2px solid',
    borderColor: theme.palette.secondary.dark,
    padding: '4px 18px',
    height: 140,
    borderRadius: 10,
    alignItems: 'center',
  },
  paperStartStake: {
    display: 'flex',
    marginBottom: 16,
    flexDirection: 'row',
    padding: '0px 18px',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'space-between',
    },
  },
  paperHeader: {
    color: theme.palette.secondary.dark,
    fontSize: 14,
  },
  paperContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    color: theme.palette.secondary.main,
  },
  paperStakeContent: {
    flex: 1,
    marginTop: 10,
    marginBottom: 14,
    minWidth: 70,
  },
  paperBtnLeft: {
    backgroundColor: theme.palette.primary.dark,
    width: 92,
    borderRadius: 20,
  },
  enablePoolBtn: {
    fontSize: 20,
    backgroundColor: theme.palette.primary.main,
    minWidth: 172,
    borderRadius: 10,
    height: 48,
    '&:disabled': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.secondary.dark,
    },
  },
  enablePoolBtnContainer: {
    marginTop: 25,
    justifyContent: 'center',
  },
  startStakingBtn: {
    [theme.breakpoints.up('md')]: {
      fontSize: 20,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
    minWidth: '100%',
    backgroundColor: theme.palette.primary.main,
    borderRadius: 10,
    height: 48,
    '&:disabled': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.secondary.dark,
    },
  },
  value: {
    fontWeight: 'bold',
  },
  stakeButtonContainer: {
    marginTop: 10,
    marginBottom: 14,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      minWidth: 150,
    },
    '& > button:nth-child(2)': {
      marginLeft: 16,
    },
  },
  stakeButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    borderRadius: 10,
    [theme.breakpoints.up('md')]: {
      fontSize: 20,
      height: 48,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
  },
  modalRoot: {
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    height: 250,
    width: 412,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    backgroundColor: theme.palette.background.paper,
    overflow: 'hidden',
    // padding: '0px 35px',
  },
  modalHeader: {
    padding: '0px 35px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  modalHeaderClose: {
    cursor: 'pointer',
  },
  modalTitle: {
    alignSelf: 'start',
    marginTop: 12,
    padding: '0px 35px',
    color: theme.palette.secondary.dark,
  },
  balance: {
    fontSize: 14,
    marginRight: 32,
    marginTop: 8,
    alignSelf: 'flex-end',
  },
  pkr: {
    padding: '10px 12px',
    fontWeight: 'bold',
    backgroundColor: theme.palette.dark,
    color: 'rgba(194, 195, 197, 0.65)',
    textAlign: 'center',
    lineHeight: 2,
    borderRadius: 10,
  },
  input: {
    flex: 1,
    margin: '8px 0',
    marginLeft: 12,
  },
  inputRoot: {
    display: 'flex',
    minWidth: 341,
    borderRadius: 10,
    alignItems: 'center',
    backgroundColor: theme.palette.dark,
  },
  max: {
    color: theme.palette.primary.main,
  },
  splitter: {
    color: theme.palette.secondary.dark,
  },
  confirmButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    width: 341,
    borderRadius: 10,
    margin: '0px auto',
    marginTop: 12,
    justifySelf: 'center',
    '&:disabled': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.secondary.dark,
    },
  },
  note: {
    padding: 40,
    maxWidth: 600,
    marginTop: 24,
    display: 'flex',
    margin: '0 auto',
    borderRadius: 20,
    flexDirection: 'column',
    backgroundColor: theme.palette.primary.main,
  },
  lightButton: {
    marginTop: 16,
    padding: '8px 0',
    fontSize: 16,
    fontWeight: 'bolder',
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover svg': {
      position: 'relative',
      animationName: '$slide',
      animationDuration: '0.8s',
      animationTimingFunction: 'linear',
      animationIterationCount: '8',
      color: theme.palette.secondary.main,
    },
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  '@keyframes slide': {
    '0%, 100%': { left: 0 },
    '50%': { left: 4 },
  },
  connectedWallet: {
    color: theme.palette.white,
    marginTop: 55,
    fontSize: 14,
  },
  termsContainer: {
    [theme.breakpoints.up('md')]: {
      lineHeight: 3,
    },
    justifyContent: 'center',
    display: 'flex',
    textAlign: 'right',
    flexDirection: 'row',
  },
  terms: {
    color: theme.palette.primary.main,
  },
  content: {
    alignContent: 'center',
    display: 'flex',
    width: '100%',
  },
  alignCenter: {
    textAlign: 'center',
  },
  disconnect: {
    color: theme.palette.primary.main,
    fontSize: 14,
  },
  endsBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  countdown: {
    marginLeft: 8,
    lineHeight: 1,
  },
  warningMessage: {
    height: 120,
    padding: 45,
    textAlign: 'center',
  },
  noteDeposit: {
    color: theme.palette.secondary.main,
    fontSize: 10,
    width: '100%',
    padding: '0px 35px',
    marginTop: 4,
  },
  card: {
    width: 360,
  },
  titleHeader: {
    width: '100%',
    textAlign: 'center',
  },
  loadingPool: {
    marginRight: 150,
  },
}));

export default useStyles;
