import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  indicator: {
    position: 'absolute',
    top: '50%',
    left: 0,
    background: theme.palette.primary.main,
    height: '100%',
    transform: 'translateY(-50%)',
    borderRadius: '100px',
    width: '100%',
    zIndex: 9,
    float: 'left',
    display: 'inline-block',
    transition: '0.4s ease',
    boxShadow: '0px 4px 4px 0px rgb(102 102 255 / 0.25)',
  },
  indicator2: {
    left: 86,
  },
  toggle: {
    float: 'left',
    display: 'block',
    width: 180,
    margin: '0px auto',
    background: theme.palette.dark,
    textAlign: 'center',
    position: 'relative',
    listStyle: 'outside none none',
    padding: 0,
    '& > li': {
      float: 'left',
      width: 'calc(100% / 2)',
      display: 'inline-block',
      transition: '0.4s ease',
    },
    border: 'solid 2px #373737',
    borderRadius: 30,
  },
  choice: {
    zIndex: 10,
    padding: '10px 15px',
    position: 'relative',
    cursor: 'pointer',
    color: theme.palette.white,
  },
  activeChoice: {
    color: theme.palette.white,
  },
}));
