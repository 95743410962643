/* eslint-disable react/no-multi-comp */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Drawer, List, ListSubheader, Hidden } from '@material-ui/core';
import NavItem from 'components/NavItem';
import PerfectScrollbar from 'react-perfect-scrollbar';
import navConfig from './config';
import useStyles from './styles';

function NavItems({ items, subheader, id, ...rest }) {
  const classes = useStyles();

  return (
    <List key={id}>
      {subheader && (
        <ListSubheader className={classes.subheader} disableSticky>
          {subheader}
        </ListSubheader>
      )}
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        [],
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={item.id}
        label={item.label}
        open={Boolean(open)}
        title={item.title}
        badge={item.badge}
      >
        <NavItems
          {...{
            key: item.id,
            depth: depth + 1,
            pathname,
            items: item.items,
          }}
        />
      </NavItem>,
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={item.id}
        label={item.label}
        title={item.title}
        target={item.target}
        anchor={item.anchor}
        badge={item.badge}
      />,
    );
  }

  return acc;
}

function NavBar({ openMobile, onMobileClose, className, ...rest }) {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }

    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <div {...rest} className={clsx(classes.root, className)}>
      <PerfectScrollbar>
        <nav className={classes.navigation}>
          {navConfig.map(list => (
            <NavItems
              {...{
                items: list.items,
                subheader: list.subheader,
                pathname: location.pathname,
                key: list.id,
              }}
            />
          ))}
        </nav>
      </PerfectScrollbar>
    </div>
  );

  return (
    <>
      <Hidden mdUp>
        <Drawer
          anchor="left"
          classes={{
            paper: classes.mobileDrawer,
          }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Drawer
          anchor="left"
          classes={{
            paper: classes.desktopDrawer,
          }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
