import React, { Fragment } from 'react';
import WalletProvider from 'providers/WalletProvider';
import StakingProvider from 'providers/StakingProvider';
import useWeb3Modal from 'hooks/useWeb3Modal';

// include providers here
const baseProviders = [];

export const Compose = ({ components, children }) => (
  <Fragment>
    {components.reverse().reduce((acc, curr) => {
      const [Provider, props] = Array.isArray(curr)
        ? [curr[0], curr[1]]
        : [curr, {}];
      return <Provider {...props}>{acc}</Provider>;
    }, children)}
  </Fragment>
);

export const ComposeProvider = ({ children }) => {
  const [provider, loadWeb3Modal, logoutOfWeb3Modal] = useWeb3Modal();

  const providers = [
    [WalletProvider, { provider, loadWeb3Modal, logoutOfWeb3Modal }],
    [StakingProvider, { provider, loadWeb3Modal, logoutOfWeb3Modal }],
    ...baseProviders,
  ];
  return <Compose components={providers}>{children}</Compose>;
};

export default ComposeProvider;
