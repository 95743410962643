import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    padding: theme.spacing(4),
  },
  grid: {
    height: '100%',
  },
  content: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
  },
  image: {
    display: 'inline-block',
    maxWidth: 900,
    width: '100%',
  },
}));

export default useStyles;
