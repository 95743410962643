import { Button, Dialog, TextField, Typography } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { TRANSACTIONS_LOGIN_API } from 'apis/Transactions/login';
import { useSetStoreValue } from 'react-context-hook';
import ForgotPass from '../../modals/ForgotPassModal';
import React, { useEffect, useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { URL_HELPERS } from 'helpers/url';
import OTP from '../../modals/ForgotPassOTPModal';
import validate from 'validate.js';
import useStyles from './styles';
import { STORE } from 'store';
import { USERS_API } from 'apis/user';
import NewPasswordForm from '../NewPasswordForm';
import ResetPasswordComplete from 'views/LogIn/modals/ResetPasswordComplete';
import Loading from 'components/Loading';

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
  },
};

const ResetPasswordForm = props => {
  const [uuid, setUUID] = useState('');
  const [openOTP, setOpenOTP] = useState(false);
  const [openChangePass, setChangePass] = useState(false);
  const [openCompleteReset, setCompleteReset] = useState(false);
  const { setResetPassword } = props;
  const [loading, setLoading] = useState(false);

  const handleClosePass = () => {
    setResetPassword(false);
  };

  const handleOpenCompleteReset = () => {
    setCompleteReset(true);
  };

  const handleOpenOTP = res => {
    const { reset_password_otp_uuid } = res;
    setUUID(reset_password_otp_uuid);
    setOpenOTP(true);
  };

  const handleCloseOTP = () => {
    setOpenOTP(false);
  };

  const handleOpenChangePass = res => {
    setChangePass(true);
  };

  const handleCloseChangePass = () => {
    setChangePass(false);
  };

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });
  const setSnackBar = useSetStoreValue(STORE.SNACKBAR);
  const classes = useStyles();

  const handleChange = event => {
    event.persist();

    setFormState(prevFormState => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const onSuccess = () => {
    handleCloseOTP();
    handleOpenChangePass();
  };

  const onError = () => {
    setLoading(false);
    setSnackBar({
      message: 'Invalid login credentials, try again.',
      variant: 'error',
    });
  };

  const hasError = field =>
    !!(formState.touched[field] && formState.errors[field]);

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(prevFormState => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleSubmit = async event => {
    event.preventDefault();
    const payload = {
      email: formState.values.email,
    };
    setLoading(true);
    try {
      const res = await USERS_API.forgotPassword(payload);
      setLoading(false);
      //setDetails(payload);
      handleOpenOTP(res);
    } catch (e) {
      onError(e);
    }
  };

  const renderResetPasswordForm = () => {
    return (
      <div className={classes.root}>
        <Typography>FORGOT PASSWORD</Typography>
        <Typography className={classes.forgotLabel}>
          Enter your email or username below, and we will send you instructions
          to reset your password
        </Typography>
        <Typography className={classes.label}>Username or Email</Typography>
        <TextField
          name="email"
          placeholder="Email Address"
          className={classes.input}
          error={hasError('email')}
          helperText={hasError('email') ? formState.errors.email[0] : null}
          onChange={handleChange}
          value={formState.values.email || ''}
          fullWidth
          variant="outlined"
        />

        {loading ? (
          <Loading loadingText="Please wait..." />
        ) : (
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={!formState.isValid}
            className={classes.resetPasswordButton}
            fullWidth
            onClick={handleSubmit}
          >
            RESET PASSWORD
          </Button>
        )}

        <Button
          className={classes.resendButton}
          color="primary"
          onClick={handleClosePass}
        >
          Cancel
        </Button>
      </div>
    );
  };

  return (
    <>
      {openOTP ? (
        <OTP
          uuid={uuid}
          formState={formState}
          onSuccess={onSuccess}
          onClose={handleCloseOTP}
        />
      ) : openChangePass ? (
        <NewPasswordForm
          uuid={uuid}
          handleSubmit={handleCloseChangePass}
          closeDialog={handleCloseChangePass}
          handleCloseChangePass={handleCloseChangePass}
          setResetPassword={setResetPassword}
          handleOpenCompleteReset={handleOpenCompleteReset}
        />
      ) : openCompleteReset ? (
        <ResetPasswordComplete handleClosePass={handleClosePass} />
      ) : (
        renderResetPasswordForm()
      )}
    </>
  );
};

export default ResetPasswordForm;
