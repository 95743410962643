import React, { useState } from 'react';
import { Button, Typography } from '@material-ui/core';
import useStyles from '../components/ResetPasswordForm/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const ResetPasswordComplete = props => {
  const { handleClosePass } = props;
  const classes = useStyles();

  const renderResetComplete = () => {
    return (
      <div className={classes.root}>
        <Typography className={classes.labelCenter}>
          PASSWORD RESET SUCCESSFULLY
        </Typography>
        <Typography className={classes.forgotLabel}>
          Your new password was successfully submited, click the Login button
        </Typography>
        <CheckCircleIcon className={classes.checkIcon} />

        <Button
          type="submit"
          color="primary"
          variant="contained"
          className={classes.resetPasswordButton}
          fullWidth
          onClick={handleClosePass}
        >
          LOGIN
        </Button>

        <Button
          className={classes.resendButton}
          color="primary"
          onClick={handleClosePass}
        >
          Cancel
        </Button>
      </div>
    );
  };

  return <>{renderResetComplete()}</>;
};

export default ResetPasswordComplete;
