import { ethers } from 'ethers';
import { uniqBy } from 'lodash';
import { SMART_CONTRACTS } from 'constants/addresses';
// import { ABI } from 'contracts/artifacts';

export const ETHERS_SERVICE = (provider, address, networkId) => {
  // Initialize Ethers
  const signer = provider.getSigner(address); // signer account
  const CONTRACT = SMART_CONTRACTS[networkId];

  const formatBigNumber = (value, unit = 'ether') => {
    const bn = ethers.BigNumber.from(value);
    return ethers.utils.formatUnits(bn, unit);
  };

  const formatBigNumberToNumber = value => {
    const bn = ethers.BigNumber.from(value);
    return bn.toNumber();
  };

  const parseUnit = (amount, unit = 'ether') =>
    ethers.utils.parseUnits(amount, unit);

  const checkPKRBalance = async (walletAddress = address) => {
    const tokenAbi = CONTRACT.pkr.abi;
    const tokenAddress = CONTRACT.pkr.address;

    const tokenContract = new ethers.Contract(tokenAddress, tokenAbi, signer);
    const balanceBN = await tokenContract.balanceOf(walletAddress);
    return await formatBigNumber(balanceBN);
  };

  const depositPKR = async amount => {
    const tokenAbi = CONTRACT.pkr.abi;
    const tokenAddress = CONTRACT.pkr.address;

    const tokenContract = new ethers.Contract(tokenAddress, tokenAbi, signer);
    const amountToTransfer = parseUnit(amount);
    return await tokenContract.transfer(
      CONTRACT.serverAddress,
      amountToTransfer,
    );
  };

  const getUserInfo = async address => {
    const contractAbi = CONTRACT.stakePKR.abi;
    const contractAddress = CONTRACT.stakePKR.address;

    const stakingContract = new ethers.Contract(
      contractAddress,
      contractAbi,
      signer,
    );

    const data = await stakingContract.userInfo(address);
    return formatBigNumber(data[0]);
  };

  const getPendingReward = async address => {
    const contractAbi = CONTRACT.stakePKR.abi;
    const contractAddress = CONTRACT.stakePKR.address;

    const stakingContract = new ethers.Contract(
      contractAddress,
      contractAbi,
      signer,
    );

    const data = await stakingContract.pendingReward(address);
    return formatBigNumber(data);
  };

  const getBonusEndBlock = async () => {
    const contractAbi = CONTRACT.stakePKR.abi;
    const contractAddress = CONTRACT.stakePKR.address;

    const stakingContract = new ethers.Contract(
      contractAddress,
      contractAbi,
      signer,
    );

    const data = await stakingContract.bonusEndBlock();
    return formatBigNumberToNumber(data);
  };

  const getStartBlock = async () => {
    const contractAbi = CONTRACT.stakePKR.abi;
    const contractAddress = CONTRACT.stakePKR.address;

    const stakingContract = new ethers.Contract(
      contractAddress,
      contractAbi,
      signer,
    );

    const data = await stakingContract.startBlock();
    return formatBigNumberToNumber(data);
  };

  const getRemainingBlocks = async () => {
    const endBlock = await getBonusEndBlock();
    const currentBlock = await provider.getBlockNumber();

    const remainingBlocks = endBlock - currentBlock;

    if (remainingBlocks < 0) {
      return 0;
    }

    return remainingBlocks;
  };

  const getRewardPerBlock = async () => {
    const contractAbi = CONTRACT.stakePKR.abi;
    const contractAddress = CONTRACT.stakePKR.address;

    const stakingContract = new ethers.Contract(
      contractAddress,
      contractAbi,
      signer,
    );
    const data = await stakingContract.rewardPerBlock();

    return parseFloat(formatBigNumber(data));
  };

  const getTotalStaked = async () => {
    const contractAbi = CONTRACT.stakePKR.abi;
    const contractAddress = CONTRACT.stakePKR.address;

    const stakingContract = new ethers.Contract(
      contractAddress,
      contractAbi,
      signer,
    );
    const data = await stakingContract.totalStaked();

    return parseFloat(formatBigNumber(data));
  };

  const getBlockCountdown = block => `${CONTRACT.countdownUrl}/${block}`;

  const getAPR = async () => {
    const totalStaked = await getTotalStaked();

    if (totalStaked <= 0) {
      return 'N/A';
    }

    const endBlock = await getBonusEndBlock();
    const startBlock = await getStartBlock();
    const rewardPerBlock = await getRewardPerBlock();
    const totalCummulativeReward = parseFloat(
      (endBlock - startBlock) * rewardPerBlock,
    );

    return parseFloat(totalCummulativeReward / totalStaked).toFixed(2);
  };

  const getTotalStakers = async () => {
    const tokenAbi = CONTRACT.pkr.abi;
    const tokenAddress = CONTRACT.pkr.address;

    const tokenContract = new ethers.Contract(tokenAddress, tokenAbi, signer);
    const filters = await tokenContract.filters.Transfer(
      null,
      CONTRACT.stakePKR.address,
    );
    const filter = {
      fromBlock: 0,
      toBlock: 'latest',
      ...filters,
    };
    const rawLogs = await provider.getLogs(filter);

    if (!rawLogs.length) {
      return 0;
    }

    const iface = new ethers.utils.Interface(CONTRACT.pkr.abi);
    const logs = rawLogs.slice(1); // remove reward provider
    const parsedLogs = logs.map(log => iface.parseLog(log));
    const uniqueStakers = uniqBy(parsedLogs, 'args.0');

    return uniqueStakers.length;
  };

  const stakePKR = async amount => {
    const contractAbi = CONTRACT.stakePKR.abi;
    const contractAddress = CONTRACT.stakePKR.address;

    const stakingContract = new ethers.Contract(
      contractAddress,
      contractAbi,
      signer,
    );

    const amountToDeposit = parseUnit(amount);
    const { wait: onStakePKR } = await stakingContract.deposit(amountToDeposit);
    return await onStakePKR();
  };

  const withdrawStake = async amount => {
    const contractAbi = CONTRACT.stakePKR.abi;
    const contractAddress = CONTRACT.stakePKR.address;

    const stakingContract = new ethers.Contract(
      contractAddress,
      contractAbi,
      signer,
    );

    let amountToWithdraw = 0;
    if (amount) {
      amountToWithdraw = parseUnit(amount.toString());
    }

    const { wait: onWithdrawStakedPKR } = await stakingContract.withdraw(
      amountToWithdraw,
    );
    return await onWithdrawStakedPKR();
  };

  const getStakingAllowance = async address => {
    const contractAddress = CONTRACT.stakePKR.address;
    const tokenAbi = CONTRACT.pkr.abi;
    const tokenAddress = CONTRACT.pkr.address;

    const tokenContract = new ethers.Contract(tokenAddress, tokenAbi, signer);
    const allowance = await tokenContract.allowance(address, contractAddress);
    return formatBigNumber(allowance);
  };

  const approveStakingPool = async () => {
    const tokenAbi = CONTRACT.pkr.abi;
    const tokenAddress = CONTRACT.pkr.address;
    const contractAddress = CONTRACT.stakePKR.address;

    const tokenContract = new ethers.Contract(tokenAddress, tokenAbi, signer);

    const amount = parseUnit('999999999', 'ether');
    const { wait: onApprove } = await tokenContract.approve(
      contractAddress,
      amount,
    );
    return await onApprove();
  };

  return [
    ethers,
    signer,
    {
      contract: CONTRACT,
      methods: {
        depositPKR,
        getUserInfo,
        stakePKR,
        getAPR,
        withdrawStake,
        getTotalStaked,
        getTotalStakers,
        checkPKRBalance,
        getPendingReward,
        getBonusEndBlock,
        getBlockCountdown,
        getRemainingBlocks,
        approveStakingPool,
        getStakingAllowance,
      },
      utils: {
        parseUnit,
        formatBigNumber,
      },
    },
  ];
};
