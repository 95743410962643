import { useEffect, useState, useCallback } from 'react';
import Web3Modal from 'web3modal';
import { ethers } from 'ethers';
import {
  ETHEREUM_TESTNET_ID,
  INFURA_ID,
  PORTIS_ID,
} from 'constants/networkConstants';
import theme from 'theme';
import Portis from '@portis/web3';
import Torus from '@toruslabs/torus-embed';
import WalletConnectProvider from '@walletconnect/web3-provider';

function useWeb3Modal(config = {}) {
  const [provider, setProvider] = useState(null);
  const [externalProvider, setExternalProvider] = useState(null);
  const [autoLoaded, setAutoLoaded] = useState(false);
  const { autoLoad = true, infuraId = INFURA_ID } = config;

  const web3Modal = new Web3Modal({
    cacheProvider: true,
    providerOptions: {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          infuraId,
          network: 'ropsten',
          chainId: ETHEREUM_TESTNET_ID,
        },
      },
      portis: {
        package: Portis,
        options: {
          id: PORTIS_ID,
          network: 'ropsten',
        },
      },
      torus: {
        package: Torus, // required
        options: {
          networkParams: {
            host: 'ropsten', // optional
          },
        },
      },
    },
    theme: {
      main: 'rgb(199, 199, 199)',
      secondary: 'rgb(136, 136, 136)',
      hover: theme.palette.background.default,
      background: theme.palette.background.paper,
    },
  });

  // Open wallet selection modal.
  const loadWeb3Modal = async () => {
    try {
      await web3Modal.updateTheme('dark');
      const extProvider = await web3Modal.connect();
      const newProvider = new ethers.providers.Web3Provider(extProvider);
      setExternalProvider(extProvider);
      setProvider(newProvider);
    } catch (e) {
      console.log('ERROR ', e);
    }
  };

  const logoutOfWeb3Modal = useCallback(async () => {
    const { _portis: portis, torus } = externalProvider;

    if (portis?.logout) {
      portis.logout();
    }
    if (torus?.logout) {
      torus.logout();
    }
    await web3Modal.clearCachedProvider();
    await setExternalProvider(null);
    await setProvider(null);
  }, [externalProvider, web3Modal]);

  // If autoLoad is enabled and the the wallet had been loaded before, load it automatically now.
  useEffect(() => {
    if (autoLoad && !autoLoaded && web3Modal.cachedProvider) {
      loadWeb3Modal().then(() => {
        setAutoLoaded(true);
      });
    }
  }, [
    autoLoad,
    autoLoaded,
    loadWeb3Modal,
    setAutoLoaded,
    web3Modal.cachedProvider,
  ]);

  return [provider, loadWeb3Modal, logoutOfWeb3Modal];
}

export default useWeb3Modal;
