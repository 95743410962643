import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Footer, Navbar, Topbar } from './components';

const useStyles = makeStyles(theme => ({
  '@media (max-height: 570px)': {
    content: {
      height: 'auto',
    },
  },
  '@media (min-height: 571px)': {
    content: {
      height: 'calc(100% - 86px)',
    },
  },
  '@media (max-width: 1850px)': {
    '@media (max-height: 650px)': {
      content: {
        height: 'auto',
      },
    },
  },
  '@media (max-width: 1100px)': {
    content: {
      height: 'auto',
    },
  },
  root: {
    paddingTop: 56,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64,
    },
  },
  shiftContent: {
    // paddingLeft: 200,
  },
  content: {
    backgroundColor: theme.palette.background.default,
  },
}));

const Staking = props => {
  const { children, noFooter } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop,
      })}
    >
      <Topbar onSidebarOpen={() => setOpenNavBarMobile(true)} />
      {/* <Navbar
        onMobileClose={() => setOpenNavBarMobile(false)}
        openMobile={openNavBarMobile}
      /> */}
      <main className={classes.content}>
        {children}
        {!noFooter ? <Footer /> : null}
      </main>
    </div>
  );
};

Staking.propTypes = {
  children: PropTypes.node,
};

export default Staking;
