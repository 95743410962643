import { get } from 'lodash';
import { requestAPI } from 'services/request';
import { store } from 'react-context-hook';
import { STORE } from 'store';

// GENERIC API
const meAPI = ({ ...rest }) => requestAPI('GET', '/users/me', {}, { ...rest });

const forgotPasswordAPI = ({ payload }) =>
  requestAPI('POST', '/users/forgot-password', payload, { auth: false });

const verifyOTPPasswordAPI = ({ payload }) =>
  requestAPI('POST', '/users/verify-reset-password-otp', payload, {
    auth: false,
  });

const resetPasswordAPI = ({ payload }) =>
  requestAPI('POST', '/users/reset-password', payload, { auth: false });

// PROCESS API
const me = async (args = {}) => {
  const res = await meAPI({ ...args });
  const data = get(res, 'data', {});
  store.set(STORE.USER_DETAILS, data);

  return data;
};

const forgotPassword = async payload => {
  const res = await forgotPasswordAPI({ payload });
  return get(res, 'data', {});
};

const verifyOTPPassword = async payload => {
  const res = await verifyOTPPasswordAPI({ payload });
  return get(res, 'data', {});
};

const resetPassword = async payload => {
  const res = await resetPasswordAPI({ payload });
  return get(res, 'data', {});
};

export const USERS_API = {
  me,
  forgotPassword,
  verifyOTPPassword,
  resetPassword,
};
