import CreditCardIcon from '@material-ui/icons/CreditCard';
import { URL_HELPERS } from 'helpers/url';

export default [
  {
    id: 1,
    subheader: 'Menu',
    items: [
      {
        id: 1,
        title: 'Staking',
        href: URL_HELPERS.stake,
        icon: CreditCardIcon,
      },
    ],
  },
];
