import { createTheme } from '@material-ui/core/styles';
import palette from './palette';
import overrides from './overrides';

export const theme = createTheme({
  palette,
  overrides,
  props: {
    MuiAppBar: {
      color: 'inherit',
    },
    MuiTooltip: {
      arrow: true,
    },
    MuiButtonBase: {
      disableRipple: true,
    },
  },
});

export default theme;
