export const ETHEREUM_MAINNET_ID = 1;
export const ETHEREUM_TESTNET_ID = 3; // Ropsten
export const BSC_MAINNET_ID = 56;
export const BSC_TESTNET_ID = 97;
export const GANACHE_ID = 5777; // devs: change this base on your ganache network id

// eslint-disable-next-line no-undef
export const INFURA_ID = process.env.REACT_APP_INFURA_ID;
// eslint-disable-next-line no-undef
export const PORTIS_ID = process.env.REACT_APP_PORTIS_ID;

export const VALID_NETWORK_IDS_DEV = [
  // GANACHE_ID,
  // BSC_MAINNET_ID,
  // BSC_TESTNET_ID,
  // ETHEREUM_MAINNET_ID,
  ETHEREUM_TESTNET_ID,
];

export const VALID_NETWORK_IDS_PROD = [
  // GANACHE_ID,
  // BSC_MAINNET_ID,
  // BSC_TESTNET_ID,
  // ETHEREUM_MAINNET_ID,
  ETHEREUM_TESTNET_ID,
];
