import React, { useContext, useEffect, useState } from 'react';
import {
  Typography,
  Button,
  Input,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
import { STORE } from 'store';
import ForwardIcon from '@material-ui/icons/ForwardRounded';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import WalletContext from 'contexts/WalletContext';
import { find, get, isEmpty } from 'lodash';
import { useWallets } from 'hooks/wallets';
import { useBalances } from 'hooks/balances';
import { STRING_HELPERS } from 'helpers/stringAdditions';
import { useSetStoreValue } from 'react-context-hook';
import { ETHERS_SERVICE } from 'services/ethers';
import { LOGIC_HELPERS } from 'helpers/logic';
import { WALLETS_API } from 'apis/wallets';
import Loading from 'components/Loading';
import { URL_HELPERS } from 'helpers/url';
import useStyles from './styles';

const Withdraw = props => {
  const { history } = props;
  const classes = useStyles();
  const [hash, setHash] = useState('');
  const [amount, setAmount] = useState(0);
  const [balance, setBalance] = useState('0.0');
  const [refetch, setRefetch] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const setSnackbar = useSetStoreValue(STORE.SNACKBAR);

  const { provider, wallet, validNetwork, loadWeb3Modal } =
    useContext(WalletContext);
  const address = get(wallet, 'wallet', '').toLowerCase();
  const networkId = get(wallet, 'networkId', 0);

  const { data: wallets } = useWallets();
  const { data: balances, refetch: refetchBalances } = useBalances();
  const chipBalance = get(balances, '1.balance', '0.0');

  const maxValue = chipBalance;
  const displayAddress = STRING_HELPERS.renderWalletAddress(address);

  useEffect(() => {
    const fetchBalance = async () => {
      const [, , ethersHelper] = ETHERS_SERVICE(provider, address, networkId);
      const res = await ethersHelper.methods.checkPKRBalance();
      setBalance(res);
    };

    const initialized = LOGIC_HELPERS.ifElse(
      [provider, validNetwork, wallets?.length],
      true,
      false,
    );
    if (initialized) {
      const connected = find(wallets, { address });
      const status = !isEmpty(connected);
      setEnabled(status);
      setRefetch(false);

      if (status) {
        fetchBalance();
      }
    }
  }, [provider, validNetwork, address, hash, wallets, refetch]);

  const handleChange = e => {
    const pattern =
      /^((\d{1,3})([0-9]{3}){0,1}|(\d{1})([0-9]{3}){0,2}|(\d{1,7}))(\.\d{1,18})?$/i;
    const value = e.target.value;
    const match = value.match(pattern);

    if (match) {
      if (parseFloat(match) > 0 && parseFloat(match) <= parseFloat(maxValue)) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    } else {
      setDisabled(true);
    }

    setAmount(value);
  };

  const onMaxInput = () => {
    setAmount(chipBalance);
  };

  const onSync = async () => {
    try {
      setLoading(true);
      setRefetch(true);
      await WALLETS_API.sync();
      await refetchBalances();
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      setHash(null);
      const payload = { address, amount, asset_id: 1 };

      const res = await WALLETS_API.withdraw(payload);
      setHash(res.transaction_hash);

      setSnackbar({
        variant: 'success',
        message: 'Success withdrawing polker chips!',
      });
      setAmount(0);
      setLoading(false);
      await refetchBalances();
    } catch (e) {
      setSnackbar({
        variant: 'error',
        message: 'Failed withdrawing polker chips!',
      });
      setAmount(0);
      setLoading(false);
      console.log('onSubmit ERROR: ', e);
    }
  };

  const onRedirectHome = () => {
    history.push(URL_HELPERS.home);
  };

  const renderInvalidNetwork = () => (
    <div className={classes.note}>
      <Typography variant="h6" color="textPrimary" className={classes.header}>
        NETWORK INVALID!
      </Typography>
      <Typography variant="subtitle1" color="textPrimary">
        Please change to Ethereum network and connect your wallet again.
      </Typography>
    </div>
  );

  const renderConnectProvider = () => (
    <div className={classes.noteConnect}>
      <Typography variant="h6" color="textPrimary" className={classes.header}>
        WALLET PROVIDER NOT CONNECTED
      </Typography>
      <Typography variant="subtitle1" color="textPrimary">
        Connect to a wallet provider first.
      </Typography>
      <Button
        color="secondary"
        variant="contained"
        onClick={loadWeb3Modal}
        className={classes.lightButton}
        endIcon={<ForwardIcon />}
      >
        Connect wallet provider
      </Button>
    </div>
  );

  const renderDisabled = () => (
    <div className={classes.note}>
      <Typography variant="h6" color="textPrimary" className={classes.header}>
        CURRENT WALLET NOT CONNECTED!
      </Typography>
      <Typography variant="subtitle1" color="textPrimary">
        Please go to Home page to connect your wallet.
      </Typography>
      <Button
        color="secondary"
        variant="contained"
        onClick={onRedirectHome}
        className={classes.lightButton}
        endIcon={<ForwardIcon />}
      >
        Go to Home
      </Button>
    </div>
  );

  const renderSync = () => (
    <div className={classes.note}>
      <Typography className={classes.noteText}>
        <b>Note:</b> Please wait a few minutes for your transaction to finish
        and click “SYNC” at the end, in order to update your balance.
      </Typography>
      <Button
        color="secondary"
        onClick={onSync}
        variant="contained"
        className={classes.lightButton}
      >
        SYNC
      </Button>
    </div>
  );

  const renderHash = () => {
    if (!hash) {
      return null;
    }

    return (
      <Typography className={classes.hash} color="textPrimary">
        You can check if the transaction is done{' '}
        <a
          href={`https://ropsten.etherscan.io/tx/${hash}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          here
        </a>
        .
      </Typography>
    );
  };

  const renderContent = () => {
    if (!validNetwork) {
      return renderInvalidNetwork();
    }

    if (!provider) {
      return renderConnectProvider();
    }

    if (!enabled) {
      return renderDisabled();
    }

    return (
      <>
        <Typography
          className={classes.connectedWallet}
          variant="body2"
          color="textPrimary"
          gutterBottom
        >
          <b>Connected wallet:</b> {displayAddress}
        </Typography>
        <div className={classes.content}>
          <Typography className={classes.inputLabel}>From</Typography>
          <div className={classes.inputRoot}>
            <Input
              type="number"
              value={amount}
              aria-valuemax={balance}
              onChange={handleChange}
              disableUnderline
              className={classes.input}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={onMaxInput}
                  >
                    <Typography variant="subtitle2" className={classes.max}>
                      MAX
                    </Typography>
                  </IconButton>
                </InputAdornment>
              }
            />
          </div>
          <Typography color="textPrimary" className={classes.balance}>
            Balance: <b className={classes.balanceValue}>{chipBalance} PKR</b>
          </Typography>
          <Typography className={classes.inputLabel}>To</Typography>
          <div className={classes.inputRoot}>
            <Input
              type="text"
              value={amount}
              onChange={handleChange}
              className={classes.input}
              disableUnderline
            />
            <Typography variant="body2" className={classes.chips}>
              Chips
            </Typography>
          </div>
          <Typography color="textPrimary" className={classes.balance}>
            1 Chip = 1 PKR
          </Typography>
          {loading ? (
            <Loading loading={loading} loadingText="Please wait..." />
          ) : (
            <Button
              color="primary"
              variant="contained"
              onClick={onSubmit}
              disabled={disabled}
              className={classes.button}
            >
              WITHDRAW CHIPS
            </Button>
          )}
          {renderHash()}
          {renderSync()}
        </div>
      </>
    );
  };

  return (
    <>
      <div className={classes.root}>{renderContent()}</div>
    </>
  );
};

export default Withdraw;
