import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  network: {
    fontWeight: 'bold',
    color: 'rgba(255, 255, 255, 0.65)',
  },
  addressTitle: {
    fontWeight: 'bold',
  },
  address: {
    cursor: 'pointer',
    fontWeight: 'bold',
    color: theme.palette.secondary.dark,
  },
  connectedAddress: {
    cursor: 'pointer',
    fontWeight: 'bold',
    color: theme.palette.secondary.main,
  },
  disconnectedAddress: {
    cursor: 'pointer',
    fontWeight: 'bold',
    color: theme.palette.secondary.dark,
  },
  connectedStatus: {
    color: '#83c116',
  },
  disconnectedStatus: {
    color: theme.palette.secondary.dark,
  },
  addressHeader: {
    display: 'flex',
    marginBottom: 8,
  },
  addressFooter: {
    marginTop: 12,
    display: 'flex',
    justifyContent: 'space-between',
  },
  statusFooter: {
    display: 'flex',
    flexDirection: 'row',
  },
  connectedBalance: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  disconnectedBalance: {
    color: theme.palette.secondary.dark,
    fontWeight: 'bold',
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 88,
  },
  item: {
    alignItems: 'center',
  },
  connectedCard: {
    border: 'solid 2px #83c116',
    borderRadius: 15,
    minWidth: 520,
    minHeight: 177,
    boxShadow: '1px 1px 0 rgba(255,255,255,.1) inset',
    backgroundColor: '#0f0f0f',
    marginRight: 50,
    marginTop: 50,
  },
  disconnectedCard: {
    borderRadius: 15,
    minWidth: 520,
    minHeight: 177,
    boxShadow: '1px 1px 0 rgba(255,255,255,.1) inset',
    backgroundColor: '#0f0f0f',
    marginRight: 50,
    marginTop: 50,
  },
}));

export default useStyles;
