export const URL_HELPERS = {
  home: '/',
  buy: '/buy',
  withdraw: '/withdraw',
  stake: '/stake',
  settings: '/settings',
  store: '/store',
  login: '/login',
  notFound: '/not-found',
  wallet: '/wallet',
};

export const URL_TOGGLE = {
  portal: [
    URL_HELPERS.home,
    URL_HELPERS.buy,
    URL_HELPERS.withdraw,
    URL_HELPERS.settings,
    URL_HELPERS.login,
    URL_HELPERS.wallet,
  ],
  staking: [URL_HELPERS.stake],
};
