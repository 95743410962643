import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { URL_HELPERS } from 'helpers/url';
import { STORE, getAuthTokens } from 'store';
import { Footer } from 'layouts/Main/components';
import { LOGGED_IN } from 'constants/appConstants';
import { Grid, Paper, Fade, Typography } from '@material-ui/core';
import { useStoreValue, useSetStoreValue } from 'react-context-hook';
import Page from 'components/Page';
import LoginForm from './components/LoginForm';
import ResetPasswordForm from './components/ResetPasswordForm';
import useStyles from './styles';

const LogIn = props => {
  const { history, location } = props;
  const [loading, setLoading] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const userStatus = useStoreValue(STORE.USER_STATUS);
  const classes = useStyles();
  const setSnackBar = useSetStoreValue(STORE.SNACKBAR);

  useEffect(() => {
    const tokens = getAuthTokens();
    if (userStatus === LOGGED_IN && tokens) {
      history.replace(`${URL_HELPERS.home}${location.search}`);
      setSnackBar({
        message: 'Welcome!',
        variant: 'success',
      });
    }
  }, [userStatus]);

  const renderForm = () => {
    const label = resetPassword
      ? 'PASSWORD RESET'
      : 'LOGIN TO YOUR CPE ACCOUNT';
    return (
      <Fade in>
        <div className={classes.content}>
          <Typography className={classes.login}>{label}</Typography>
          <Paper elevation={0} className={classes.paper}>
            {resetPassword ? (
              <ResetPasswordForm
                history={history}
                setLoading={setLoading}
                setResetPassword={setResetPassword}
              />
            ) : (
              <LoginForm
                history={history}
                setLoading={setLoading}
                setResetPassword={setResetPassword}
              />
            )}
          </Paper>
        </div>
      </Fade>
    );
  };

  return (
    <>
      <Page title="Log In" className={classes.root}>
        <Grid item className={classes.contentRoot}>
          {renderForm()}
          <Footer light />
        </Grid>
      </Page>
    </>
  );
};

LogIn.propTypes = {
  history: PropTypes.object,
};

export default LogIn;
