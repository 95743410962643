import { makeStyles } from '@material-ui/styles';
import Background from './asset/fx2.png';

export default makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100vh',
  },
  contentRoot: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    overflowY: 'auto',
    backgroundColor: '#000',
    backgroundImage: `url(${Background})`,
    backgroundRepeat: 'no-repeat',
  },
  content: {
    flex: 1,
    padding: '0 40px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  paper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: 11,
    paddingTop: 16,
    borderRadius: 44,
    backgroundColor: theme.palette.background.default,
    height: 500,
    width: 500,
    justifyContent: 'center',
  },
  login: {
    fontSize: 20,
    textAlign: 'center',
    color: '#ffffff',
  },
}));
