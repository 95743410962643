import React from 'react';
import Route from 'components/Route';
import { URL_HELPERS } from 'helpers/url';
import ProvideAuth from 'components/ProvideAuth';
import { Switch, Redirect } from 'react-router-dom';
import {
  Home,
  BuyChips,
  Withdraw,
  Stake,
  Settings,
  Store,
  LogIn,
  NotFound,
  Wallet,
} from 'views';
import {
  Main as MainLayout,
  Minimal as MinimalLayout,
  Staking as StakingLayout,
} from 'layouts';

const Routes = () => {
  return (
    <ProvideAuth>
      <Switch>
        <Route
          component={Home}
          path={URL_HELPERS.home}
          layout={MainLayout}
          protectedRoute
          exact
        />
        <Route
          component={BuyChips}
          path={URL_HELPERS.buy}
          layout={MainLayout}
          protectedRoute
          exact
        />
        <Route
          component={Withdraw}
          path={URL_HELPERS.withdraw}
          layout={MainLayout}
          protectedRoute
          exact
        />
        <Route
          component={Stake}
          path={URL_HELPERS.stake}
          layout={StakingLayout}
          exact
        />
        <Route
          component={Settings}
          path={URL_HELPERS.settings}
          layout={MainLayout}
          protectedRoute
          exact
        />
        <Route
          component={LogIn}
          layout={MinimalLayout}
          path={URL_HELPERS.login}
          publicRoute
          exact
        />
        <Route component={Store} path={URL_HELPERS.store} exact />
        <Route
          path={URL_HELPERS.notFound}
          component={NotFound}
          layout={MinimalLayout}
          exact
        />
        <Route
          component={Wallet}
          path={URL_HELPERS.wallet}
          layout={MainLayout}
          protectedRoute
          exact
        />
        <Redirect to={URL_HELPERS.notFound} />
      </Switch>
    </ProvideAuth>
  );
};

export default Routes;
